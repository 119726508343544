<template>

    <div>
        <div class="MenuPrincipale q-pa-md">
            <div align="center">
                <span class="text-h4">Analisi dei Rischi</span>
                <br><br>
            </div>

            <div class="text-center justify-center q-pa-md q-gutter-sm"
                v-show="!is_loading">

                <q-spinner-hourglass
                    size="4em"
                />

            </div>

            <div align="center" v-show="is_loading"
                v-for="(Domanda, index) in domande"
                    v-bind:key="index"
                    v-bind="Domanda"
                    class="col-md-5 col-12"
                   >

                <fieldset>
                    <span class="text-h5">{{Domanda.label}}</span>

                    <QQQuestionario
                       :domande="Domanda.domande"
                       :analisi_necessita="preventivo.analisi_necessita"
                        v-model="domande"
                        @error-checked="onErrorChecked($event)" />

                </fieldset>
                <br>
            </div>

            <div class="row justify-center">
                <div class="col-md-4" align="center">
                    <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                        @click.native="onIndietroClicked()"
                    />
                </div>
                <div class="col-md-4" align="center">
                    <QQButton label="ANNULLA PREVENTIVO" color="blue-grey" icon="mdi-close-octagon" size="md"
                        @click.native="gotoStart()"
                    />
                </div>
                <div class="col-md-4" align="center">
                    <QQButton label="AVANTI"
                        color="blue-grey"
                        icon="mdi-arrow-right-bold"
                        size="md"
                        icon_align="right"
                        @click.native="onAvantiClicked"
                    />
                </div>
            </div>

            <q-dialog v-model="persistent" persistent transition-show="scale" transition-hide="scale" >
                <q-card class="bg-teal text-white" style="width: 500px">
                    <q-card-section>
                    <div class="text-h6">ATTENZIONE:</div>
                    </q-card-section>

                    <q-card-section class="q-pt-none">
                        {{ DlgMessage }}
                    </q-card-section>

                    <q-card-actions align="right" class="bg-white text-teal">
                    <q-btn flat label="OK" v-close-popup />
                    </q-card-actions>
                </q-card>
            </q-dialog>

        </div>
        <br><br><br><br>
    </div>
</template>

<script>

import QQButton from "@/components/QQButton.vue";
import QQQuestionario from "@/components/QQQuestionario.vue";
import commonLib from "@/libs/commonLib";
import validation from "@/libs/validation";
import api from "@/libs/api";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    name: "AnalisiRischio",

    components: {
        QQButton,
        QQQuestionario
    },

    data() {
        return {
            DlgMessage: "",
            persistent: false,
            have_errors: true,
            is_loading: true,
            preventivo: []
        }
    },
    /* =======================================================================
        computed permette di associare ad una variabile il dato restituito
        da una funzione
       ======================================================================= */
    computed: {
        ...mapState({
            area: state => state.area,
            titolo_area: state => state.titolo_area,
            dati_preventivo: state => state.formPreventivo.preventivo,
            formPreventivo: state => state.formPreventivo,
            analisi_necessita: state => state.formPreventivo.preventivo.analisi_necessita
        }),
        ...mapFields("gestioneDocumenti",{
            elenco_docs: "elenco_docs"
        }),
        /*is_loading() {
            let stato = commonLib.isEmpty(this.domande);

            return !stato;
        },*/
        domande() {
            //console.log("analisi rischio:",this.preventivo.analisi_rischio);
            return this.preventivo.analisi_rischio;
        }
    },

    methods: {
        onErrorChecked(errorList) {
            let errori = false;
            errorList.forEach(errore => {
                errori = errori || errore;
            })

            this.have_errors = errori;
        },
        ...mapActions({
            fetchElencoDocumentiTariffa: "gestioneDocumenti/fetchElencoDocumentiTariffa",
            getGeoStatiForFormField: "formPreventivo/getGeoStatiForFormField"
        }),
        filtraImportiPreventivi() {
            var preventivi = this.dati_preventivo;
            var len = preventivi.prodotti.length;

            for (var i=0;i<len;i++) {
                var prodotto = preventivi.prodotti[i];

                for (var j=0;j<prodotto.proposte.length;j++) {
                    var proposta = prodotto.proposte[j];

                    if (proposta.provvigioni === "") proposta.provvigioni = 0;
                    if (proposta.premio === "") proposta.premio = 0;
                    if (proposta.premio_minimo === "") proposta.premio_minimo = 0;
                    if (proposta.premio_semestrale === "") proposta.premio_minimo = 0;
                }

            }

            return preventivi;
        },
        async ScaricaTuttiIDocumenti() {
            let prodotti = this.preventivo.prodotti;
            for (let indiceProdotto=0; indiceProdotto < prodotti.length; indiceProdotto++) {
                let prodotto = prodotti[indiceProdotto];
                //console.log("proposte:",prodotto.proposte);

                for (let indiceProposta=0;indiceProposta<prodotto.proposte.length;indiceProposta++) {
                    let proposta = prodotto.proposte[indiceProposta];

                    var create_at = commonLib.castDateToIso(this.formPreventivo.preventivo.create_at);
                    var dati = {
                        id_tariffa: proposta.tariffa,
                        data_create_at: create_at
                    }

                    let doc = await this.fetchElencoDocumentiTariffa(dati);
                    let nome_tariffa = proposta.tariffa;

                    //console.log("nome tariffa:",nome_tariffa);

                    this.elenco_docs[nome_tariffa] = new Array();
                    this.elenco_docs[nome_tariffa]= doc;
                }
            }
        },

        isDomandaVisible(Domande, SingolaDomanda) {
            //console.log("SingolaDo0manda.is_visible_if:",SingolaDomanda.is_visible_if);

            if (!commonLib.isEmpty(SingolaDomanda.is_visible_if)) {
                // Recupera i nome del campo di riferimento
                let NomeCampo = SingolaDomanda.is_visible_if.split(":")[0];
                let ValoreCampo = SingolaDomanda.is_visible_if.split(":")[1];

                //console.log("nome,valore:",NomeCampo,ValoreCampo);

                // Cerca il campo con l'attributo NAME uguale a quello cercato
                let Campo = Domande.filter((domanda) => {
                    return (
                        domanda.name == NomeCampo &&
                        this.isDomandaVisible(Domande, domanda)
                    );
                });

                // Cerca il valore impostato sul campo appena trovato
                if (Campo.length > 0) {
                    let value = Campo[0].value;

                    return ValoreCampo.includes(value);
                }

                // Non lo trova nel questionario analisi rischio - cerca la domanda nell'analisi delle necessita
                Campo = this.analisi_necessita.filter(domanda => {
                    return domanda.name === NomeCampo;
                });

                if (Campo.length > 0) {
                    let value = Campo[0].value;

                    return ValoreCampo.includes(value);
                }

                return false;
            }
            return true;
        },
        controllaSeQuestionarioCompilato(questionario) {
            let tot = questionario.length;

            for (let i = 0; i < tot; i++) {
                // Controlla solo le domande che risultano visibili

                if (
                    questionario[i].is_visible &&
                    questionario[i].type !== "group_checkbox" &&
                    questionario[i].type !== "checkbox" &&
                    questionario[i].type !== "button" &&
                    questionario[i].type !== "separator" &&
                    questionario[i].type !== "title"
                ) {
                    let visible = this.isDomandaVisible(questionario, questionario[i]);

                    // controlla le regole di validazione
                    let regola = questionario[i].validation;
                    let valore = questionario[i].value;
                    let attributi = regola.split("|");

                    if (visible && regola !== "") {
                        let { Msg, esito } = validation.verificaRegole(
                            attributi,
                            valore
                        );
                        //console.log("Messaggio:", Msg);
                        if (Msg != "") Msg = "";

                        if (esito) return false;
                        if (commonLib.isEmpty(questionario[i].value)) return false;
                    }

                    //if (this.isEmpty(questionario[i].value)) return false;

                    // return true;
                }
            }

            return true;
        },
        async onAvantiClicked() {
            let statoControllo = false;
            this.is_loading = false;

            //console.log("domande:",this.domande);

            this.domande.forEach (domanda => {
                let DomandeDaAnalizzare = domanda.domande;

                if (domanda.domande.length > 0) {
                    // statoControllo = commonLib.controllaSeQuestionarioCompilato(DomandeDaAnalizzare);
                    statoControllo = this.controllaSeQuestionarioCompilato(DomandeDaAnalizzare);

                    if (!statoControllo) {

                        this.DlgMessage = "Il questionario non risulta compilato correttamente. Verifica prima di continuare";
                        this.persistent = true;
                        this.have_errors = true;
                        this.is_loading = true;
                        return;
                    }
                }
            });

            if (!statoControllo) {
                this.is_loading = true;
                return;
            }

            this.persistent = false;

            // Cancella il carrello dallo store
            this.$store.commit("formPreventivo/resetIndiceProdotto");

            var dati_preventivo = await this.filtraImportiPreventivi();

            console.log("preventivo:",dati_preventivo);

            // Invia il questionario al BackEnd per visualizzare i premi dei preventivi
            let pacchetto = {
                preventivo: dati_preventivo
            };

            let url = process.env.VUE_APP_API_URL + 'formPreventivo/proposte?area='+this.area;
            //let oJSon = await api.postJSonToController(this.area + "/preventivi",pacchetto,"proposte");
            let oJSon = await api.postJSonToController(url,pacchetto);

            console.log("prodotti ricevuti:",oJSon.data.preventivo.prodotti);

            // Memorizza il JSON nello store
            this.$store.commit("formPreventivo/updateProdotti",oJSon.data.preventivo.prodotti);

            await this.ScaricaTuttiIDocumenti();

            this.$emit("on-avanti-clicked");

            this.is_loading = true;
        },
        onIndietroClicked() {
            this.$emit("on-back-clicked");
        },
        gotoStart() {
            this.$q.dialog({
                cancel: {
                    focus: true,
                    flat: false,
                    label: "Non annullare",
                    color: "primary"
                },
                ok: {
                    color: "secondary",
                    flat: false,
                    label: "Si"
                },
                title: 'Attenzione',
                message: 'Vuoi davvero annullare il preventivo?'
            }).onOk(() => {
                this.$router.push({name : "Preventivi"});
            });
        }

    },
    async mounted() {
        window.scrollTo(0,0);
        var stati = await this.getGeoStatiForFormField();

        this.preventivo = this.filtraImportiPreventivi();

        console.log("mounth preventivo:",this.preventivo);

        // Cicla tutte le domande per cercare i campi doce inserire la lista di stati
        var domanda_cercata = [];
        this.domande.filter(d => {
            var dom = d.domande.filter(d1 => {
                if (d1.name === "paese_destinazione_viaggio") {
                    domanda_cercata.push(d1);
                    return true;
                }
                return false;
            });

            return dom.length > 0;
        });

        //console.log("domanda cercata:",domanda_cercata[0],stati,"puppa");

        if (domanda_cercata.length > 0) {
            domanda_cercata[0].options = stati;
        }
    }

}
</script>

<style scoped>

</style>
