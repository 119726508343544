<template>
    <div class="DatiregistraPreventivo">
        <div align="center">
            <span class="text-h4">Richiesta emissione Polizze</span>
        </div>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">

            <q-spinner-hourglass
                size="4em"
            />

        </div>

        <div v-show="isClienteDefinito()"></div>

        <div v-for="(Domanda, index) in getDomande"
                            v-bind:key="index"
                            v-bind="Domanda"
                            class="col-md-5 col-12">
            <fieldset>
                <span class="text-h5">{{Domanda.label}}</span>

                <QQQuestionario
                    :domande="Domanda.domande"
                    v-model="Domanda.domande"
                    @on-btn-clicked="onButtonClicked"
                    @input="onButtonClicked"
                    @qq-questionario-change="onQuestionarioChange($event)"
                    @qq-questionario-inputSelect-filter="onInputSelectFilter($event)"
                />

                <!-- @qq-questionario-filter="onQuestionarioFilter($event)" //-->

            </fieldset>
            <br>
        </div>
        <hr>

        <div class="row" align="center">
            <div class="col-12 col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-12 col-md-4" align="center">
                <QQButton label="ANNULLA EMISSIONE" color="blue-grey" icon="mdi-close-octagon" size="md"
                    @click.native="gotoStart()"
                />
            </div>
            <div class="col-12 col-md-4" align="center">
                <QQButton label="AVANTI"
                    color="blue-grey"
                    icon="mdi-arrow-right-bold"
                    size="md"
                    icon_align="right"
                    :disabled="!pulsante_abilitato"
                    @click.native="onAvantiClicked"
                />
            </div>
        </div>

        <q-dialog v-model="show_dialog"
            persistent transition-show="scale"
            transition-hide="scale" >
            <q-card class="bg-teal text-white" style="width: 500px">
                <q-card-section>
                <div class="text-h6">ATTENZIONE:</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    Il questionario non risulta compilato
                    correttamente. Verifica prima di continuare
                </q-card-section>

                <q-card-actions align="right" class="bg-white text-teal">
                    <q-btn flat label="OK" v-close-popup />
                </q-card-actions>
            </q-card>

        </q-dialog>

        <QQDialogElenco
            title="Elenco Clienti trovati in anagrafe"
            sub_title="Sono stati trovati i seguenti nominativi. Seleziona il Cliente dalla lista:"
            v-model="dialog_visible"
            :rows="rows"
            :columns="columns"
            @on-click-nuovo="onButtonNuovoCliente"
            @on-record-selected="onRecordSelected"
        />

        <br><br><br><br>

    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import QQQuestionario from "@/components/QQQuestionario.vue";
    import QQButton from "@/components/QQButton.vue";
    import api from "@/libs/api";
    import commonLib from "@/libs/commonLib";
    import policy from "@/libs/policy";
    import QQDialogElenco from "@/components/Clienti/QQDialogElenco.vue";
    import { mapFields } from 'vuex-map-fields'
    import { mapGetters } from "vuex";

    export default {
        name: "DatiCreaPolizza",
        data() {
            return {
                show_dialog: false,
                MsgDialog: "",
                dialog_visible: false,
                id_unita_operativa_cliente_selezionato: "",
                dati_modificati: false,
                pulsante_abilitato: true,
                is_quotazione: false,
                info_cellulare: {},
                cellulare_cliente: "",
                email_cliente: "",
                pec_cliente: ""
            }
        },
        components: {
            QQQuestionario,
            QQButton,
            QQDialogElenco
        },
        //watch: {
            //"domande.0.domande.1": function (newVal, oldVal) {
                //console.log(newVal, oldVal);
            //},
        //},
        computed: {
            ...mapGetters([
                "jwtPayload"
            ]),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                rows: state => state.gestioneClienti.righe,
                columns: state => state.gestioneClienti.colonne,
                tutti_i_comuni: state => state.geoStore.comuni //,
                // is_loading: state => state.formPreventivo.is_loading
            }),
            //...mapFields('formPreventivo', [
            //    "is_loading",
            //]),
            ...mapFields('formPreventivo', {
                domande: "dati_cliente",
                is_loading: "is_loading"
            }),
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
        //    ...mapFields('gestioneFatture', [
        //        "guid_pratica"
        //    ]),
            getUrlConfermaProposte() {
                return "/"+this.$store.state.area+"/preventivi";
            },
            getElencoClienti() {
                return [];
            },
            getDomande() {
                if (commonLib.isEmpty(this.domande)) return [];

                return this.domande;
            },
            prodottoIsTaylorMade() {
                var prodotti = this.formPreventivo.preventivo.prodotti;
                //console.log("prodotti:",prodotti);
                var prodotti_attivi = prodotti.filter(p => {
                    // return (p.is_taylormade === true) || (p.is_checked === true) || (p.is_taylor_made === true);

                    return (p.is_checked === true) && ((p.is_taylormade === true) || (p.is_taylor_made === true));
                });

                //console.log("prodotti_attivi:",prodotti_attivi);

                return prodotti_attivi.length > 0;
            },
            getConsulenzaTotale() {
                var consulenza = 0;
                for (var i=0;i<this.formPreventivo.preventivo.prodotti.length;i++) {
                    var prodotto = this.formPreventivo.preventivo.prodotti[i];
                    if (prodotto.is_checked)
                        consulenza += parseFloat(prodotto.consulenza);
                }

                return consulenza;
            }
        },
        methods: {
            attivaPulsanti() {
                this.pulsante_abilitato = true;
            },
            disattivaPulsanti() {
                this.pulsante_abilitato = false;
                 setTimeout(()=>{
                    this.attivaPulsanti()
                }, 60000);
            },
            async onInputSelectFilter($event) {
                let input_value = $event.input_value;
                let domanda = $event.domanda;
                let update = $event.update;
                let abort = $event.abort;

                var estensione = domanda.name.split("_")[1].toLowerCase();

                if(input_value.length < 3) {
                    abort();
                    return;
                }

                await this.$store.dispatch("geoStore/initComuni");



                switch(estensione) {
                    case "residenza":
                        update(async ()=>{
                            await this.onComuneResidenzaChange(input_value);
                        });
                    break;
                    case "corrispondenza":
                        update(()=>{
                            this.onComuneCorrispondenzaChange(input_value);
                        });
                    break;
                    case "nascita":
                        update(()=>{
                            this.onComuneNascitaChange(input_value);
                        });
                    break;
                }
            },

            async onQuestionarioChange(domanda) {

                switch(domanda.name) {
                    default: return;

                    case "comune_residenza":
                        await this.onComuneResidenzaChange(domanda.value);
                    break;

                    case "comune_corrispondenza":
                        await this.onComuneCorrispondenzaChange(domanda.value);
                    break;

                    case "comune_nascita":
                        await this.onComuneNascitaChange(domanda.value);
                    break;

                    case "cellulare":
                        this.cellulare_cliente = domanda.value;
                    break;

                /*    case "email":
                        this.email_cliente = domanda.value;
                    break;

                    case "pec":
                        this.pec_cliente = domanda.value;
                    break;*/

                }

            },

            async onComuneResidenzaChange(input_value) {
                if (commonLib.isEmpty(input_value)) return;

                var domande = this.getDomande;

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);

                let dom_comune_residenza =
                    domande[0].domande.find(
                        dom => dom.name === "comune_residenza");
                dom_comune_residenza.options = elenco_mappato;

                let dom_cap_residenza =
                    domande[0].domande.find(
                        dom => dom.name === "cap_residenza");

                if (comune_selezionato.length > 0) {
                    dom_cap_residenza.options =
                        await this.onMapCap(comune_selezionato[0].cap);
                    dom_cap_residenza.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                        dom_cap_residenza.value = comune_selezionato[0].cap[0];

                    let dom_provincia_residenza =
                        domande[0].domande.find(
                            dom => dom.name === "provincia_residenza");

                    dom_provincia_residenza.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },

            async onComuneCorrispondenzaChange(input_value) {
                if (commonLib.isEmpty(input_value)) return;

                var domande = this.getDomande;

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);

                let dom_comune_corrispondenza =
                    domande[0].domande.find(
                        dom => dom.name === "comune_corrispondenza");
                dom_comune_corrispondenza.options = elenco_mappato;

                let dom_cap_corrispondenza =
                    domande[0].domande.find(
                        dom => dom.name === "cap_corrispondenza");

                if (comune_selezionato.length > 0) {
                    dom_cap_corrispondenza.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap_corrispondenza.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                        dom_cap_corrispondenza.value = comune_selezionato[0].cap[0];

                    let dom_provincia_corrispondenza =
                        domande[0].domande.find(
                            dom => dom.name === "provincia_corrispondenza");

                    dom_provincia_corrispondenza.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },

            async onComuneNascitaChange(input_value) {
                if (commonLib.isEmpty(input_value)) return;

                var domande = this.getDomande;

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);

                let dom_comune_nascita =
                    domande[0].domande.find(
                        dom => dom.name === "comune_nascita");
                dom_comune_nascita.options = elenco_mappato;

                if (comune_selezionato.length > 0) {
                    let dom_provincia_nascita =
                        domande[0].domande.find(
                            dom => dom.name === "provincia_nascita");

                    dom_provincia_nascita.value =
                        comune_selezionato[0].sigla_provincia;
                }

            /*    risposta = risposta.toLowerCase();

                if (commonLib.isEmpty(this.formPreventivo.dati_cliente[0].comuni_residenza))
                    this.formPreventivo.dati_cliente[0].comuni_residenza = await apiGeo.fetchComuniForName(risposta);

                var elenco =
                    this.formPreventivo.dati_cliente[0].comuni_nascita.filter(comune => {
                        return comune.nome.toLowerCase() === risposta;
                    });

                if (elenco.length > 0) {
                    let dom_provincia_nascita =
                        this.domande[0].domande.find(
                            dom => dom.name === "provincia_nascita");

                    dom_provincia_nascita.value =
                        elenco[0].sigla_provincia;

                } */
            },

            async onMapComuni(comuni) {
                if (commonLib.isEmpty(comuni)) return [];

                return comuni.map(comune => {
                    return {
                        is_visible_if:"",
                        label: comune.nome,
                        value: comune.nome.trim()
                    };
                });
            },

            async onMapCap(cap) {
                if (commonLib.isEmpty(cap)) return [];

                return await cap.map(c => {
                    return {
                        is_visible_if:"",
                        label: c,
                        value: c.trim()
                    };
                });
            },

            async onProvinciaResidenzaChange(provincia) {
                // Compila l'elenco dei comuni associati alla provincia selezionata
                //console.log("provincia residenza cambiata", provincia);
                var domande = this.getDomande;
                var comuni = await this.getGeoComuniForProvincia(provincia);
                let dom_comune_residenza =
                    domande[0].domande.find(
                        dom => dom.name === "comune_residenza");
                dom_comune_residenza.options = comuni;
                dom_comune_residenza.value = "";
            },
            async onProvinciaCorrispondenzaChange(provincia) {
                // Compila l'elenco dei comuni associati alla provincia selezionata
                //console.log("provincia corrispondenza cambiata", provincia);
                var comuni = await this.getGeoComuniForProvincia(provincia);
                var domande = this.getDomande;
                let dom_comune_corrispondenza =
                    domande[0].domande.find(
                        dom => dom.name === "comune_residenza");
                dom_comune_corrispondenza.options = comuni;
                dom_comune_corrispondenza.value = "";
            },
            async onProvinciaNascitaChange(provincia) {
                // Compila l'elenco dei comuni associati alla provincia selezionata
                var comuni = await this.getGeoComuniForProvincia(provincia);
                var domande = this.getDomande;
                let dom_comune_nascita =
                    domande[0].domande.find(
                        dom => dom.name === "comune_nascita");
                dom_comune_nascita.options = comuni;
                dom_comune_nascita.value = "";
            },
            onButtonNuovoCliente() {
                this.formPreventivo.IDCliente = "";
                this.visualizzaDomandeNascoste();
            },
            onRecordSelected(recordSelezionato) {
                this.formPreventivo.IDCliente = "";
                let json_cliente = recordSelezionato.json_cliente;

                if (!commonLib.isEmpty(json_cliente.altri_dati))
                    this.id_unita_operativa_cliente_selezionato = json_cliente.altri_dati[0].id_unita_operativa;

                //console.log("json_cliente:",json_cliente);

                this.popolaCampiQuestionario(json_cliente);
                this.dialog_visible = false;
            },
            /* ===============================================================
               Questo metodo viene chiamato quando il campo IDCliente
               nel formPreventivo non risulta valorizzato. Questo significa
               che l'operatore si è limitato a registrare il preventivo
               senza andare oltre. In questi casi, il programma scansiona
               i dati del questionario definito nella tabella
               domande_analisi con context="dati_cliente" e popola i campi
               con le informazioni recuperate dal database documentale
               in base alla scelta dalla lista dei clienti trovati
               =============================================================== */
            popolaCampiQuestionario(json_cliente) {
                var domande = this.getDomande;
                domande.forEach(domanda => {
                    domanda.domande.forEach(singola_domanda => {

                        let name = singola_domanda.name;
                        let value = json_cliente[name];
                        let valore = "";
                        let nome_campo = "";
                        let tipo_campo = "";

                        if (name === "data_nascita") {
                            let dati = (value+"").split("-");
                            value = dati[2]+"/"+dati[1]+"/"+dati[0];
                        }

                        if ((singola_domanda.name !== "pulsante_cerca") &&
                            (singola_domanda.name !== "pulsante_reset")) {
                                singola_domanda.is_visible = true;
                            }

                        switch(singola_domanda.name) {
                            case "codice_fiscale":
                            case "partita_iva":
                            case "data_nascita":
                            case "ragione_sociale":
                            case "nome":
                            case "cognome":
                            case "tipo_persona":
                                singola_domanda.is_disabled = true;
                                //singola_domanda.value = value;
                            break;
                        }

                        if (value !== "")
                            singola_domanda.value = value;

                        if (commonLib.isEmpty(json_cliente.altri_dati)) {
                            // Se è presente solo l'anagrafica generale senza indirizzi allora
                            // è inutile andare avanti
                            return;
                        }

                        var dati_unita = json_cliente.altri_dati[0];

                        if (commonLib.isEmpty(value)) {
                            switch(name) {
                                case "professione":
                                    value = dati_unita.professione;
                                break;
                                case "corrispondenza_diverso_residenza":
                                    value = "si";
                                    if ((dati_unita.indirizzi[0].indirizzo === dati_unita.indirizzi[1].indirizzo) &&
                                        (dati_unita.indirizzi[0].comune === dati_unita.indirizzi[1].comune)) {
                                            value = "no";
                                        }
                                break;

                                case "indirizzo_residenza":
                                case "comune_residenza":
                                case "cap_residenza":
                                case "provincia_residenza":
                                case "indirizzo_corrispondenza":
                                case "comune_corrispondenza":
                                case "cap_corrispondenza":
                                case "provincia_corrispondenza":
                                    value = "";
                                    valore = name.split("_");
                                    nome_campo = valore[0];
                                    tipo_campo = valore[1];
                                    valore = dati_unita.indirizzi
                                                .filter(singolo_indirizzo => {
                                                     return singolo_indirizzo.tipo === tipo_campo;
                                                });
                                    if (valore.length > 0) {
                                        value = valore[0][nome_campo];
                                    }
                                break;

                                case "numero_residenza":
                                case "numero_corrispondenza":
                                    value = "";
                                    valore = name.split("_");
                                    nome_campo = "civico";
                                    tipo_campo = valore[1];
                                    valore = dati_unita.indirizzi
                                                .filter(singolo_indirizzo => {
                                                     return singolo_indirizzo.tipo === tipo_campo;
                                                });
                                    if (valore.length > 0) {
                                        value = valore[0][nome_campo];
                                    }
                                break;

                                case "telefono":
                                case "cellulare":
                                value = ""
                                    valore = dati_unita.recapiti.filter(p => {
                                        return p.tipo === name;
                                    });
                                    if (valore.length > 0)
                                        value = valore[0].value;
                                break;
                                case "email":
                                case "pec":
                                    value = ""
                                    valore = dati_unita.recapiti.filter(p => {
                                        return p.tipo === name;
                                    });
                                    if (valore.length > 0)
                                        value = valore[0].value;
                                break;
                            }
                        }

                        if (value !== "")
                            singola_domanda.value = value;

                    });
                });
            },
            /*sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },*/
            /* ===========================================================
               Questo metodo viene chiamato quando nel formPreventivo
               il campo IDCliente risulta valorizzato. Questo significa
               che l'operatore ha in precedenza gia selezionato il Cliente
               dall'anagrafica. In questi casi, il programma va a cercare
               i dati nel database documentale e popola il questionario
               con i dati recuperati.
               =========================================================== */
            async popolaCampiQuestionarioDaDocumentale(json_cliente) {
                let valore = "";
                let dati = [];
                let dati_residenza = [];
                let dati_corrispondenza = [];

                var domande = this.getDomande;
                //let cliente_definito = !commonLib.isEmpty(this.formPreventivo.IDCliente);

                domande.forEach(domanda => {
                    domanda.domande.forEach(singola_domanda => {
                        // Riempie i campi sulla root del JSON
                        singola_domanda.value = json_cliente[singola_domanda.name];
                        singola_domanda.is_visible = true;

                        if (commonLib.isEmpty(json_cliente.altri_dati)) {
                            return;
                        }

                        var dati_unita = json_cliente.altri_dati[0];

                        switch(singola_domanda.name) {
                            case "tipo_persona":
                            case "cognome":
                            case "nome":
                            case "codice_fiscale":
                            case "partita_iva":
                            case "data_nascita":
                            // case "codice_univoco_destinatario":
                                singola_domanda.is_disabled = true;
                            break;
                            case "professione":
                               singola_domanda.value = dati_unita.professione;
                               if (this.is_quotazione) singola_domanda.is_disabled = true;
                            break;
                            case 'codice_univoco_destinatario':
                                singola_domanda.value = json_cliente.codice_univoco_destinatario;
                                console.log("json cliente : ",json_cliente);
                            break;
                            case "indirizzo_residenza":
                            case "comune_residenza":
                            case "cap_residenza":
                            case "provincia_residenza":
                            case "indirizzo_corrispondenza":
                            case "comune_corrispondenza":
                            case "cap_corrispondenza":
                            case "provincia_corrispondenza":
                                valore = singola_domanda.name.split("_");

                                dati = dati_unita.indirizzi.filter(p => {
                                    return p.tipo == valore[1];
                                });

                                singola_domanda.value = dati[0][valore[0]];
                                if (this.is_quotazione) singola_domanda.is_disabled = true;
                            break;

                            case "numero_residenza":
                            case "numero_corrispondenza":
                                valore = singola_domanda.name.split("_");
                                dati =dati_unita.indirizzi.filter(p => {
                                    return p.tipo == valore[1];
                                });
                                singola_domanda.value = dati[0]["civico"];
                                if (this.is_quotazione) singola_domanda.is_disabled = true;
                            break;

                            case "corrispondenza_diverso_residenza":
                                dati_residenza = dati_unita.indirizzi.filter(p => {
                                    return p.tipo == "residenza";
                                });
                                dati_corrispondenza = dati_unita.indirizzi.filter(p => {
                                    return p.tipo == "corrispondenza";
                                });

                                if ((dati_residenza.indirizzo == dati_corrispondenza.indirizzo) &&
                                   (dati_residenza.comune == dati_corrispondenza.comune) &&
                                   (dati_residenza.provincia == dati_corrispondenza.provincia)) {
                                       singola_domanda.value = "no";
                                   } else {
                                       singola_domanda.value = "si";
                                   }
                                   if (this.is_quotazione) singola_domanda.is_disabled = true;
                            break;

                            case "telefono":
                            case "cellulare":
                                dati = dati_unita.recapiti.filter(p => {
                                    return p.tipo == singola_domanda.name;
                                });
                                singola_domanda.value = dati[0].value;
                                if (this.is_quotazione) singola_domanda.is_disabled = true;
                            break;
                            case "email":
                            case "pec":
                                dati = dati_unita.recapiti.filter(p => {
                                    return p.tipo == singola_domanda.name;
                                });
                                singola_domanda.value = dati[0].value;
                                if (this.is_quotazione) singola_domanda.is_disabled = true;
                            break;

                            case "pulsante_cerca":
                                singola_domanda.is_visible = false;
                                if (this.is_quotazione) singola_domanda.is_disabled = true;
                            break;

                            case "pulsante_reset":
                                singola_domanda.is_visible = true;
                                if (this.is_quotazione) singola_domanda.is_disabled = true;
                            break;
                        }
                    });
                });
            },
            visualizzaDomandeNascoste() {
                var domande = this.getDomande;
                domande.forEach(domanda => {
                    domanda.domande.forEach(singola_domanda => {
                        if ((singola_domanda.name !== "pulsante_cerca") &&
                            (singola_domanda.name !== "pulsante_reset")) {
                                singola_domanda.is_visible = true;
                            }

                        switch(singola_domanda.name) {
                            case "codice_fiscale":
                            case "partita_iva":
                            case "data_nascita":
                            case "ragione_sociale":
                            case "nome":
                            case "cognome":
                            case "tipo_persona":
                            case "pulsante_cerca":
                            case "pulsante_reset":
                                if ((singola_domanda.value !== "") && (!commonLib.isEmpty(singola_domanda.value)))
                                    singola_domanda.is_disabled = true;
                            break;
                        }
                    });
                });
            },
            nascondiDomande() {
                var domande = this.getDomande;
                domande.forEach(domanda => {
                    domanda.domande.forEach(singola_domanda => {
                        if ((singola_domanda.name !== "pulsante_cerca") &&
                            (singola_domanda.name !== "pulsante_reset")) {
                                singola_domanda.is_visible = true;
                            }
                        if (singola_domanda.name === "pulsante_cerca") singola_domanda.is_visible = true;
                        if (singola_domanda.name === "pulsante_reset") singola_domanda.is_visible = false;

                        //console.log("nascondiDomande name:",singola_domanda.name);
                        switch(singola_domanda.name) {
                            case "codice_fiscale":
                            case "partita_iva":
                            case "data_nascita":
                            case "nome":
                            case "cognome":
                            case "ragione_sociale":
                            case "tipo_persona":
                            case "pulsante_cerca":
                            case "pulsante_reset":
                            case "separatore":
                            case "separatore1":
                            case "titolo_identifica_persona":
                           // case "codice_univoco_destinatario":
                                singola_domanda.is_disabled = false;
                                if (typeof(singola_domanda.value) === "object") {
                                    singola_domanda.value = null;
                                } else
                                    singola_domanda.value = "";

                            break;

                            default:
                                singola_domanda.is_visible = false;
                            break;
                        }
                    });
                });
            },
            CercaNelQuestionario(valore_domanda) {
                var domanda = this.domande[0].domande.filter(d => {
                    return d.name === valore_domanda;
                });

                return domanda[0];
            },
            async onButtonClicked(label) {
                if (label === "Cerca") {
                    let tipo_persona    = this.CercaNelQuestionario("tipo_persona");
                    let cognome_cli     = this.CercaNelQuestionario("cognome");
                    let nome_cli        = this.CercaNelQuestionario("nome");
                    let CDFisc          = this.CercaNelQuestionario("codice_fiscale");
                    let partita_iva     = this.CercaNelQuestionario("partita_iva");
                    let ragione_sociale = this.CercaNelQuestionario("ragione_sociale");
                    let data_nascita    = this.CercaNelQuestionario("data_nascita");

                /*    let Questionario = {
                        domande: [
                            this.domande[0].domande[0],
                            this.domande[0].domande[1],
                            this.domande[0].domande[2],
                            this.domande[0].domande[3],
                            this.domande[0].domande[4],
                            this.domande[0].domande[5],
                            this.domande[0].domande[6]
                        ]
                    }; */

                    let Questionario = {
                        domande: [
                            tipo_persona,
                            cognome_cli,
                            nome_cli,
                            CDFisc,
                            partita_iva,
                            ragione_sociale,
                            data_nascita
                        ]
                    }

                    if (!await commonLib.controllaSeQuestionarioCompilato(Questionario.domande)) {
                        this.show_dialog = true;

                        return;
                    }

                    var domande = this.getDomande;

                    // Cancella tutte le domande secondarie
                   domande[0].domande.forEach(domanda => {
                        if ((domanda.name !== "codice_fiscale") &&
                            (domanda.name !== "partita_iva") &&
                            (domanda.name !== "nome") &&
                            (domanda.name !== "cognome") &&
                            (domanda.name !== "ragione_sociale") &&
                            (domanda.name !== "tipo_persona") &&
                            (domanda.name !== "data_nascita")
                        ) {
                            domanda.value = "";
                        }
                    })

                    let codice_fiscale = domande[0].domande.filter(domanda => {
                        return domanda.name === "codice_fiscale";
                    });

                    let nome = domande[0].domande.filter(domanda => {
                        return domanda.name === "nome";
                    });

                    let cognome = domande[0].domande.filter(domanda => {
                        return domanda.name === "cognome";
                    });

                    if ((codice_fiscale[0].value !== null) && (codice_fiscale[0].value !== "")) {
                        if (!commonLib.controllaCodiceFiscale(codice_fiscale[0].value,cognome[0].value,nome[0].value)) {
                            this.$q.dialog({
                                    title: 'Attenzione',
                                    message: 'Il codice fiscale inserito è formalmente errato.'
                                });
                            return;
                        }
                    }

                    // Effettua la chiamata al backend
                    this.formPreventivo.IDCliente = "";

                    //console.log("questionario:",Questionario);

                    await this.fetchCercaInAnagrafe(Questionario);

                    this.dati_modificati = true;

                    if (this.rows.length == 0) {
                        this.$q.dialog({
                                title: 'Attenzione',
                                message: 'Nessun Cliente identificato in Anagrafe. Completa il questionario per un nuovo inserimento.',
                                ok: {
                                    color: "secondary",
                                    flat: false,
                                    label: "Ok"
                                }
                            });
                            //.onOk(() => {
                                // Imposta la visualizzazione dei campi nascosti
                                this.formPreventivo.IDCliente = "";

                                this.domande[0].domande.forEach(domanda => {

                                    if (domanda.name === "pulsante_cerca") domanda.is_visible = false;
                                    if (domanda.name === "pulsante_reset") domanda.is_visible = true;
                                    if (domanda.name === "codice_fiscale") domanda.validation = "required";
                                    if (domanda.name === "partita_iva") domanda.validation = "required";
                                });
                                this.visualizzaDomandeNascoste();

                        //    });
                        return;
                    }

                    // Nasconde il pulsante cerca e visualizza il pulsante reset
                    domande[0].domande.forEach(domanda => {
                        if (domanda.name === "pulsante_cerca") domanda.is_visible = false;
                        if (domanda.name === "pulsante_reset") domanda.is_visible = true;
                    });

                    // Visualizza la finestra con il risultato della ricerca
                    this.dialog_visible = true;
                }

                if (label === "Nuova ricerca") {
                    this.dati_modificati = true;
                    this.formPreventivo.IDCliente = "";
                    this.nascondiDomande();

                    this.domande[0].domande.forEach(domanda => {
                        switch(domanda.name) {
                            case "codice_fiscale":
                            case "partita_iva":
                                domanda.validation = "";
                                break;
                        }
                    });
                }

            },
            ...mapActions({
                fetchQuestionarioDatiCliente: "formPreventivo/fetchQuestionarioDatiCliente",
                fetchCercaInAnagrafe: "gestioneClienti/fetchCercaInAnagrafe",
                fetchElencoDocumenti: "gestioneDocumenti/fetchElencoDocumenti",
                fetchElencoPreventivi: "formPreventivo/fetchElencoPreventivi",
                fetchFormPreventivo: "formPreventivo/fetchFormPreventivo",
                popolaProvinceEStati: "formPreventivo/popolaProvinceEStati",
                getGeoComuniForProvincia: "formPreventivo/getGeoComuniForProvincia",
                getGeoComuniForName: "formPreventivo/getGeoComuniForName",
                getGeoCapForComune: "formPreventivo/getGeoCapForComune",
                isEmailBoxInformativoSender: "formPreventivo/isEmailBoxInformativoSender",
                isQuotazione: "gestioneQuotazioni/isQuotazione",
                resetQuestionariAssuntivi: "gestioneQuotazioni/resetQuestionariAssuntivi",
                loadQuestionariAssuntivi: "gestioneQuotazioni/loadQuestionariAssuntivi",
                isCellulareUsatoTroppo: "gestioneOTP/isCellulareUsatoTroppo",
                infoCellulare: "gestioneOTP/infoCellulare",
                setEmailCliente: "gestioneClienti/setEmailCliente",
                // fetchComuni: "formPreventivo/fetchComuni"
            }),

            onIndietroClicked() {
                if (this.dati_modificati) {
                    this.$q.dialog({
                        cancel: {
                            focus: true,
                            flat: false,
                            label: "No, rimani qui",
                            color: "primary"
                        },
                        ok: {
                            color: "secondary",
                            flat: false,
                            label: "Si, crea nuovo"
                        },
                        title: 'Attenzione',
                        message: 'Sono state apportate modifiche ai dati. Tornando indietro verrà creato un nuovo preventivo. Vuoi continuare?'
                    }).onOk(() => {
                        this.formPreventivo.preventivo.id = "";
                        this.formPreventivo.step = 6;
                    });
                } else
                    this.formPreventivo.step = 6;
            },
            getDomandaValue(domanda) {
                var domande = this.getDomande;
                let valore = domande[0].domande.find(p => {return p.name === domanda});

                if (commonLib.isEmpty(valore)) return "";
                if ((commonLib.isEmpty(valore.value)) || (valore.value === "null")) return "";

                return valore.value;
            },
            creaJsonCliente() {
                let indirizzo_corrispondenza = this.getDomandaValue("indirizzo_corrispondenza");
                let comune_corrispondenza = this.getDomandaValue("comune_corrispondenza");
                let cap_corrispondenza = this.getDomandaValue("cap_corrispondenza");
                let provincia_corrispondenza = this.getDomandaValue("provincia_corrispondenza");
                let civico_corrispondenza = this.getDomandaValue("numero_corrispondenza");

                if (indirizzo_corrispondenza === "") indirizzo_corrispondenza = this.getDomandaValue("indirizzo_residenza");
                if (comune_corrispondenza === "") comune_corrispondenza = this.getDomandaValue("comune_residenza");
                if (cap_corrispondenza === "") cap_corrispondenza = this.getDomandaValue("cap_residenza");
                if (provincia_corrispondenza === "") provincia_corrispondenza = this.getDomandaValue("provincia_residenza");
                if (civico_corrispondenza === "") civico_corrispondenza = this.getDomandaValue("numero_residenza");

                var id_unita_operativa = policy.IdUnitaOperativa();
                var nome_unita_operativa = this.jwtPayload.nome_unita_operativa;

                if ((this.id_unita_operativa_cliente_selezionato !== "") &&
                    (id_unita_operativa !== this.id_unita_operativa_cliente_selezionato)) {
                    id_unita_operativa = this.id_unita_operativa_cliente_selezionato;
                }

                let result = {
                    id_intermediario_capogruppo: policy.IdIntermediarioCapogruppo(),
                    intermediario_capogruppo: "",
                    tipo_persona: this.getDomandaValue("tipo_persona"),
                    nome: this.getDomandaValue("nome"),
                    cognome: this.getDomandaValue("cognome"),
                    ragione_sociale: this.getDomandaValue("ragione_sociale"),
                    data_nascita: this.getDomandaValue("data_nascita"),
                    comune_nascita: this.getDomandaValue("comune_nascita"),
                    provincia_nascita: this.getDomandaValue("provincia_nascita"),
                    stato_nascita: this.getDomandaValue("stato_nascita"),
                    sesso: this.getDomandaValue("sesso"),
                    codice_fiscale: this.getDomandaValue("codice_fiscale"),
                    partita_iva: this.getDomandaValue("partita_iva"),
                    codice_univoco_destinatario: this.getDomandaValue("codice_univoco_destinatario"),
                    altri_dati: [
                        {
                            id_unita_operativa: id_unita_operativa,
                            unita_operativa: nome_unita_operativa,
                            professione: this.getDomandaValue("professione"),
                            indirizzi: [
                                {
                                    tipo: "residenza",
                                    provincia:  this.getDomandaValue("provincia_residenza"),
                                    comune: this.getDomandaValue("comune_residenza"),
                                    indirizzo:  this.getDomandaValue("indirizzo_residenza"),
                                    civico: this.getDomandaValue("numero_residenza"),
                                    cap: this.getDomandaValue("cap_residenza")
                                },
                                {
                                    tipo: "corrispondenza",
                                    provincia: provincia_corrispondenza,
                                    comune: comune_corrispondenza,
                                    indirizzo: indirizzo_corrispondenza,
                                    civico: civico_corrispondenza,
                                    cap: cap_corrispondenza
                                }
                            ],
                            recapiti: [
                                { tipo: "telefono", value: this.getDomandaValue("telefono") },
                                { tipo: "cellulare", value: this.getDomandaValue("cellulare") },
                                { tipo: "email", value: this.getDomandaValue("email") },
                                { tipo: "pec", value: this.getDomandaValue("pec") },
                            ]
                        }
                    ]
                }
                console.log("result:",result);

                return result;
            },
            async onAvantiClicked() {

                console.log("evento ricevuto");

                // Controlla che tutte le domande siano state compilate
                var domande = this.getDomande;
                let statoValidazione = await commonLib.controllaSeQuestionarioCompilato(domande[0].domande);

                console.log("stato validazione:",statoValidazione);

                // cerca lo stato della domanda professione
                var domanda_professione = domande[0].domande.find(dom => {
                    return dom.name === "professione";
                });

                statoValidazione = statoValidazione && domanda_professione.is_visible;

                if (!statoValidazione) {
                    // this.show_dialog = true;
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Il questionario non risulta compilato correttamente. Verifica prima di continuare'
                    });
                    return;
                }

                var EmailSender = await this.isEmailBoxInformativoSender(this.formPreventivo.preventivo.id);
                this.$store.dispatch("gestioneClienti/setEmailDisabled",!EmailSender.is_sender);

                //this.$store.dispatch("gestioneClienti/setEmailDisabled",true);
                this.$store.dispatch("gestioneClienti/setNavDisabled",true);
                this.show_dialog = false;
                this.dialog_visible = false;

                console.log("stato cellofono:",this.formPreventivo.cellulare_bloccato);



                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'L`indirizzo di posta elettronica verrà utilizzato per inviare i documenti al Cliente. Verifica che sia inserito in modo corretto prima di continuare',
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "Preferisco ricontrollare",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Va bene cosi"
                    }
                }).onOk(async () => {
                    // Disattiva i pulsanti PERICOLOSI
                    this.disattivaPulsanti();

                    // Aggiorna il numero di cellulare sulle domande cosi non fa l'aggiornamento e ci rimette quello vecchio dopo la modifica
                    var len = this.domande[0].domande.length;
                    for(var i=0;i<len;i++) {
                        var domanda = this.domande[0].domande[i];

                        switch(domanda.name) {
                            case "cellulare":
                                if (this.cellulare_cliente !== "") this.domande[0].domande[i].value = this.cellulare_cliente;
                            break;
                            case "email":
                                this.domande[0].domande[i].value = this.getDomandaValue("email");
                                await this.setEmailCliente(this.domande[0].domande[i].value);
                            break;
                            case "pec":
                                this.domande[0].domande[i].value = this.getDomandaValue("pec");
                            break;
                        }
                    }

                    // QUI registra i dati del Cliente su DB
                    let url = process.env.VUE_APP_API_URL + 'clienti/registra';
                    let json_cliente = this.creaJsonCliente();
                    console.log("json_cliente:",json_cliente);
                    let oJSon = await api.postJSonToController(url,{ cliente: json_cliente, guid_preventivo: this.formPreventivo.preventivo.id  });

                    this.formPreventivo.IDCliente = oJSon.data.idCliente;

                    var localFormPreventivo = this.formPreventivo;

                    // Pulisce il campo provvigioni se non e' settato correttamente
                    var prodotti = localFormPreventivo.preventivo.prodotti;
                    for (var k=0;k<prodotti.length;k++) {
                        var prodotto = prodotti[k];

                        for (var j=0;j<prodotto.proposte.length;j++) {
                            var proposta = prodotto.proposte[j];
                            if (commonLib.isEmpty(proposta.provvigioni)) proposta.provvigioni = 0;
                            proposta.provvigioni = parseFloat(proposta.provvigioni.toFixed(2));
                        }
                    }

                    // Registra o aggiorna il preventivo
                    var result = {
                        formPreventivo: localFormPreventivo
                    };

                    url = process.env.VUE_APP_API_URL + 'formPreventivo/registraPreventivo';
                    var NuovoFormPreventivo = await api.postJSonToController(url,result);

                    this.formPreventivo.preventivo.id = NuovoFormPreventivo.data.formPreventivo.preventivo.id;
                    this.formPreventivo.preventivo.create_at = NuovoFormPreventivo.data.formPreventivo.preventivo.create_at;
                    this.formPreventivo.persona_operativa = NuovoFormPreventivo.data.formPreventivo.persona_operativa;
                    this.formPreventivo.unita_operativa = NuovoFormPreventivo.data.formPreventivo.unita_operativa;
                    this.formPreventivo.preventivo.prodotti = NuovoFormPreventivo.data.formPreventivo.preventivo.prodotti;
                    // Memorizza il numero di cellulare anche sui dati del formPreventivo cosi rimane aggiornato
                    var domanda_cellofono = this.formPreventivo.dati_cliente[0].domande.find(d => {
                        return d.name === "cellulare";
                    });
                   // domanda_cellofono.value = this.cellulare_cliente;

                    var id_unita_operativa = this.formPreventivo.unita_operativa.id_soggetto;

                    var altri_dati = json_cliente.altri_dati.find(a => {
                        return a.id_unita_operativa === id_unita_operativa;
                    });
                    var recapito = altri_dati.recapiti.find(r => {
                        return r.tipo === "cellulare";
                    });

                    domanda_cellofono.value = recapito.value;
                    this.cellulare_cliente = recapito.value;


                    // console.log("DatiCreaPolizza => formPreventivo:",recapito);

                    var tipo_persona = json_cliente.tipo_persona;
                    var is_persona_fisica = (tipo_persona == "persona_fisica");

                    // carica l'elenco dei documenti
                    let elenco = await this.fetchElencoDocumenti(is_persona_fisica);
                    this.$store.state.elenco_documenti = elenco;

                    // Carica anche le domande del questionario assuntivo
                    await this.loadQuestionariAssuntivi(this.formPreventivo.preventivo.id);

                    if (this.prodottoIsTaylorMade) {
                        this.$router.push({name : "Preventivi.QuestionariAssuntivi.Questionario"});
                    } else {
                        this.$router.push({name : "Preventivi.DownloadDocumenti"});
                    }

                });
            },
            async isClienteDefinito() {
                let cliente_definito = !commonLib.isEmpty(this.formPreventivo.IDCliente);

                if (cliente_definito) {
                    // Legge i dati del Cliente partendo dall'ID
                    let idCliente = this.formPreventivo.IDCliente;
                    let url = process.env.VUE_APP_API_URL + "clienti/"+idCliente;
                    let oJSon = await api.getJSonFromController(url);

                    //console.log("oJSon:",oJSon);
                    await this.popolaCampiQuestionarioDaDocumentale(oJSon.data.cliente);
                }

                //console.log("cliente_definito:",cliente_definito);

                return cliente_definito;
            },
            async gotoStart() {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "Non annullare",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si"
                    },
                    title: 'Attenzione',
                    message: 'Vuoi davvero annullare il preventivo?'
                }).onOk(() => {
                    this.fetchElencoPreventivi("professionisti");
                    this.$router.push({name : "Preventivi"});
                });
            },

            async initStoreComuni() {
                // await this.$store.dispatch("geoStore/initComuni");
            }
        },
        async mounted() {
            window.scrollTo(0,0);
            this.is_loading = true;
            await this.resetQuestionariAssuntivi();
            await this.fetchQuestionarioDatiCliente();
            await this.popolaProvinceEStati();
            await this.initStoreComuni();

            this.is_quotazione = await this.isQuotazione(this.formPreventivo.preventivo.id);

            // this.guid_pratica = this.formPreventivo.preventivo.id;

            console.log("guid_pratica:",this.guid_pratica);

            // TODO - Probabilmente la consulenza la pialla QUI
            if (this.getConsulenzaTotale === 0) {
                if (!commonLib.isEmpty(this.formPreventivo.preventivo.id)) {
                    await this.fetchFormPreventivo(this.formPreventivo.preventivo.id);
                    console.log("Recuperato formPreventivo direttamente dal Preventivo");
                }


            }

            //var domande = this.getDomande;
            //let statoValidazione = await commonLib.controllaSeQuestionarioCompilato(domande[0].domande);



        },
    /*    async activated() {
            this.is_loading = true;
            await this.resetQuestionariAssuntivi();
            this.is_quotazione = await this.isQuotazione(this.formPreventivo.preventivo.id);
        } */
    }
</script>

<style scoped>

</style>
