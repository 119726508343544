<template>
    <div class="DatiregistraPreventivo">
        <div align="center">
            <span class="text-h4">Registra Preventivo anonimo</span>
            <hr>
        </div>

        <fieldset>
            <span class="text-h5">Informazioni di registrazione</span>

            <QQQuestionario :domande="dati_prospect.domande" v-model="dati_prospect.domande"/>
        </fieldset>
        <br>

        <hr>
        <div class="row" align="center">
            <div class="col-12 col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-12 col-md-4" align="center">
                <QQButton label="ANNULLA PREVENTIVO" color="blue-grey" icon="mdi-close-octagon" size="md"
                    @click.native="gotoStart()"
                />
            </div>
            <div class="col-12 col-md-4" align="center">
                <QQButton label="REGISTRA" color="blue-grey" icon="done_outline" size="md"
                    :disabled="!pulsante_attivo"
                    @click.native="onAvantiClicked"
                />
            </div>
        </div>
        <br><br><br><br>
        <q-dialog v-model="show_dialog"
            persistent transition-show="scale"
            transition-hide="scale" >
            <q-card class="bg-teal text-white" style="width: 500px">
                <q-card-section>
                <div class="text-h6">ATTENZIONE:</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    Il questionario non risulta compilato
                    correttamente. Verifica prima di continuare
                </q-card-section>

                <q-card-actions align="right" class="bg-white text-teal">
                    <q-btn flat label="OK" v-close-popup />
                </q-card-actions>
            </q-card>

        </q-dialog>

    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import api from "@/libs/api";
    import QQQuestionario from "@/components/QQQuestionario.vue";
    import QQButton from "@/components/QQButton.vue";
    import commonLib from "@/libs/commonLib";

    export default {
        name: "ConfermaProposte",
        data() {
            return {
                persistent: false,
                MsgDialog: "",
                show_dialog: false,
                pulsante_attivo: true
            }
        },
        components: {
            QQQuestionario,
            QQButton
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                dati_prospect: state => state.formPreventivo.preventivo.dati_prospect,
                formPreventivo: state => state.formPreventivo
            }),
            getUrlConfermaProposte() {
                return "/"+this.$store.state.area+"/preventivi";
            }
        },
        methods: {
            ...mapActions({
                fetchElencoPreventivi: "formPreventivo/fetchElencoPreventivi"
            }),
            attivaPulsanti() {
                this.pulsante_abilitato = true;
            },
            disattivaPulsanti() {
                this.pulsante_abilitato = false;
                 setTimeout(()=>{
                    this.attivaPulsanti()
                }, 10000);
            },
            onIndietroClicked() {
                this.$emit('on-back-clicked');
            },
            async onAvantiClicked() {
                // Verifica che il questionario sia stato correttamente compilato
                if (await commonLib.controllaSeQuestionarioCompilato(this.dati_prospect.domande)) {
                    this.disattivaPulsanti();

                    var localFormPreventivo = this.formPreventivo;

                    // Pulisce il campo provvigioni se non e' settato correttamente
                    var prodotti = localFormPreventivo.preventivo.prodotti;
                    for (var i=0;i<prodotti.length;i++) {
                        var prodotto = prodotti[i];

                        for (var j=0;j<prodotto.proposte.length;j++) {
                            var proposta = prodotto.proposte[j];
                            if (commonLib.isEmpty(proposta.provvigioni)) proposta.provvigioni = 0;
                            proposta.provvigioni = parseFloat(proposta.provvigioni.toFixed(2));
                        }
                    }

                    // effettua il POST verso il backend
                    var result = {
                        formPreventivo: localFormPreventivo
                    };

                    var url = process.env.VUE_APP_API_URL + 'formPreventivo/registraPreventivo';
                    await api.postJSonToController(url,result);

                    // ricarica la lista dei preventivi
                    await this.fetchElencoPreventivi(this.area);

                   this.$router.push({name : "Preventivi"});
                } else {
                    this.show_dialog = true;
                }
            },
            gotoStart() {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "Non annullare",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si"
                    },
                    title: 'Attenzione',
                    message: 'Vuoi davvero annullare il preventivo?'
                }).onOk(() => {
                    this.$router.push({name : "Preventivi"});
                });
            }
        },
        mounted() {
            window.scrollTo(0,0);
        }

    }
</script>

<style scoped>
    /*h4,h5 {
        padding: 0px;
        margin: 0px;
    }
    .col-12, .col-md-6 {
        padding: 6px;
    }*/
</style>
