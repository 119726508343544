<template>
    <q-dialog v-model="value" persistent
        transition-show="flip-down"
        transition-hide="flip-up"
        full-width>
        <q-card>
            <q-bar class="bg-indigo  text-white">
                <div>{{title}}</div>

                <q-space />

                <q-btn dense flat icon="close" v-close-popup @click.native="onClickBtn">
                    <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                </q-btn>
            </q-bar>

        <q-card-section>
          <div class="text-h6">{{sub_title}}</div>
        </q-card-section>

            <q-card-section class="q-pt-none" style="height: 300px;">

                <!--    @row-click="selezionaRecord" //-->
                <q-table
                    :data="rows"
                    :columns="columns"
                    dense
                    row-key="name"
                    class="my-sticky-header-table no-box-shadow q-mt-none">

            <!--    <template v-slot:top-right>
                    <div class="panel-cerca">
                        <q-input borderless dense debounce="300" v-model="filter" placeholder="Cerca">
                            <template v-slot:append>
                                <q-icon name="search" />
                            </template>
                        </q-input>
                    </div>
                </template> //-->
                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                            style="font-size: 14px; border-bottom: 1px SOLID #808080;">
                            <strong>{{ col.label }}</strong>
                        </q-th>
                    </q-tr>
                </template>

                <template v-slot:body="props">
                    <q-tr :props="props" @click.native="selezionaRecord(props.row)" style="cursor: pointer;">
                        <q-td key="id" :props="props">
                            {{ props.row.id }}
                        </q-td>
                        <q-td key="seleziona" :props="props">
                            <div align="center">
                                <q-btn color="blue-grey-6"

                                    size="sm">Seleziona</q-btn>
                            </div>
                        </q-td>
                        <q-td key="id_unita_operativa" :props="props">
                            {{ props.row.id_unita_operativa}}
                        </q-td>
                        <q-td key="unita_operativa" :props="props">
                            {{ props.row.unita_operativa}}
                        </q-td>
                        <q-td key="tipo_persona" :props="props">
                            {{ props.row.tipo_persona}}
                        </q-td>
                        <q-td key="nominativo" :props="props">
                            {{ props.row.nominativo}}
                        </q-td>
                        <q-td key="indirizzo" :props="props">
                            {{ props.row.indirizzo}}
                        </q-td>
                        <q-td key="comune" :props="props">
                            {{ props.row.comune}}
                        </q-td>
                        <q-td key="provincia" :props="props">
                            {{ props.row.provincia.toUpperCase()}}
                        </q-td>
                        <q-td key="codice_fiscale" :props="props">
                            {{ props.row.codice_fiscale.toUpperCase()}}
                        </q-td>
                        <q-td key="partita_iva" :props="props">
                            {{ props.row.partita_iva.toUpperCase()}}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>

            </q-card-section>

            <q-card-actions align="right">

                <q-btn label="INSERISCI NUOVO" v-close-popup color="primary"
                    @click.native="onClickNuovo"
                />

                <q-btn label="ANNULLA SELEZIONE" v-close-popup color="indigo"
                    @click.native="onClickAnnulla"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
    export default {
        name: 'QQDialogElenco',
        data() {
            return {
                RecordSelezionato: [],
            }
        },
        props: {
            value: {
                type: Boolean,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            sub_title: {
                type: String,
                required: false,
                default: ""
            },
            columns: {
                type: Array,
                required: false
            },
            rows: {
                type: Array,
                required: false
            }
        },
        methods: {
            onClickAnnulla() {
                this.$emit('input',false);
            },
            onClickNuovo() {
                this.$emit('on-click-nuovo',false);
            },
            selezionaRecord(row) {
                //console.log("row:",row);
                this.RecordSelezionato = row;
                this.$emit("on-record-selected",this.RecordSelezionato);
            }
        }
    }
</script>
