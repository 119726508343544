<template>
    <!-- ================================================================
        Il componente si occupa di visualizzare l'elenco delle proposte
        ovvero dei preventivi calcolati su ogni singolo prodotto
        selezionato. Permette di scegliere un singolo prodotto e di
        modificare alcuni parametri del prodotto ricalcolando il premio.
        Il prodotto selezionato viene caricato nel carrello che contiene
        l'elenco dei prodotti selezionati per l'emissione.
         ================================================================ //-->
    <div class="ElencoProposte q-pa-md">

        <div class="row justify-center">

            <div class="col-md-7 col-12" align="center">
                <br><br>
                <span class="text-h4">Elenco Proposte</span>
                <hr>
            </div>

            <div class="col-md-5 col-8" style="padding: 8px;">
                <QQCarrello :carrello="prodottiSelezionati" />
            </div>
        </div>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_mounted"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div align="center" v-if="is_mounted">
            <fieldset>
                <legend><h5>{{ ProdottoCorrente.label }}</h5></legend>

                <div class="row">
                    <div v-for="(proposta, index) in ProdottoCorrente.proposte.slice(0,3)"
                         v-bind:key="index"
                         class="col-md-4 col-12">

                        <QQProposta
                            :consulenza="consulenza"
                            :proposta="proposta"
                            :is_tailormade="ProdottoCorrente.is_tailormade"
                            v-model="proposta.is_checked"
                            :elenco_documenti="getElencoDocumenti(proposta)"
                            @update:is_checked="onPropostaIsCheckedUpdate(proposta, $event)"
                        />
                    </div>

                    <div v-show="prodottoIsSingleTailorMade" class="col-md-8 col-12 allinea-centro">
                        <div class="row justify-center">
                            <div class="col-12 col-md-8 text-h6" align="justify">
                                ATTENZIONE:<br><br>
                                I dati inseriti nel questionario di Analisi del Rischio non hanno consentito di elaborare
                                quotazioni rispondenti ai parametri richiesti. Bisogna procedere con
                                una quotazione personalizzata.
                                Seleziona il prodotto a fianco e compila i dati richiesti prima di andare avanti.
                                <br><br>
                                Grazie
                            </div>
                            <div class="col-12 col-md-8 text-h6" align="right">
                                Il Team di sviluppo
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-12" align="center">
                        <q-expansion-item
                            v-model="altre_proposte_visibili"
                            expand-separator
                            name="visualizza_altre_proposte"
                            label="Visualizza altre proposte"
                            caption="Clicca QUI per visualizzarle"
                            style="font-size: 18px; border: 1px SOLID #000;"
                            color="primary"
                            label-lines
                            v-show="ProdottoCorrente.proposte.length > 3">

                            <div class="row" style="font-size: 14px;">
                                <div v-for="(proposta, index) in ProdottoCorrente.proposte.slice(3,30)" v-bind:key="index" class="col-md-4 col-12 allinea-centro">

                                    <QQProposta
                                        :consulenza="consulenza"
                                        :proposta="proposta"
                                        :is_tailormade="ProdottoCorrente.is_tailormade"
                                        v-model="proposta.is_checked"
                                        :elenco_documenti="getElencoDocumenti(proposta)"
                                        @update:is_checked="onPropostaIsCheckedUpdate(proposta, $event)"
                                    />
                                </div>
                            </div>

                        </q-expansion-item>

                    </div>
                </div>
            </fieldset>

            <!--  v-show="ha_consulenza" -->
            <fieldset>
                <legend><h5>Consulenza</h5></legend>

                <div class="justifi-center row">
                    <div class="col-12 col-md-12">
                        <table border="0" width="100%">
                            <tbody>
                                <tr>
                                    <td width="60%" align="center">
                                        <q-slider
                                            v-show="ha_consulenza"
                                            color="red"
                                            thumb-size="50px"
                                            track-size="15px"
                                            label-always
                                            :label-value="consulenza.toFixed(2) + '€'"
                                            v-model="consulenza"
                                            :min="importo_consulenza_minima"
                                            :max="importo_consulenza_massima"
                                            :inner-min="importo_consulenza_minima"
                                            :inner-max="importo_consulenza_massima"
                                            @change="onCambiaValoreConsulenza" />

                                        <div v-show="!ha_consulenza">
                                            <h5>Prodotto senza consulenza</h5>
                                        </div>
                                    </td>
                                    <td align="right" NoWrap  colspan="2">
                                        <h5>Costo di acquisto garantito</h5>
                                        <h2 class="q-mb-sm"><strong id="premio_totale_consulenza">{{ premio_totale }}</strong> Euro</h2>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <q-expansion-item
                                            v-show="isSede"
                                            v-model="altri_dati_visibili"
                                            expand-separator
                                            icon="mdi-eye-plus"
                                            name="visualizza_altre_proposte"
                                            label="Ulteriori informazioni"
                                            caption="Clicca QUI per visualizzarle"
                                            style="font-size: 18px; border: 1px SOLID #000;"
                                            color="primary"
                                            track-size="40px"
                                            label-lines>
                                            <br>
                                            <q-list bordered separator>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Premio proposta selezionata</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ premio_selezionato.toFixed(2) }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Importo Diritti</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ importo_diritti_aby.toFixed(2) }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Consulenza minima selezionabile</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ importo_consulenza_minima.toFixed(2) }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Consulenza massima selezionabile</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ importo_consulenza_massima.toFixed(2) }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Consulenza impostata</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ consulenza.toFixed(2) }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Provvigioni di Compagnia</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{ importo_provvigioni_selezionate.toFixed(2) }} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Quota consulenza Unit&agrave; Operativa</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{importo_provvigioni_consulenza_collaboratore.toFixed(2)}} Euro</q-item-section>
                                                </q-item>
                                                <q-item>
                                                    <q-item-section><q-item-label align="left">Quota consulenza Aby</q-item-label></q-item-section>
                                                    <q-item-section align="right">{{importo_provvigioni_consulenza_aby}} Euro</q-item-section>
                                                </q-item>
                                            </q-list>


                                        </q-expansion-item>
                                    </td>
                                    <td align="right"><h6>Provvigioni:</h6> </td>
                                    <td align="right"><h5><strong>{{ provvigioni_totali }}</strong> Euro</h5></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </fieldset>

        </div>
        <hr>
        <div class="row justify-center">
            <div class="col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-md-4" align="center">
                <QQButton label="ANNULLA PREVENTIVO" color="blue-grey" icon="mdi-close-octagon" size="md"
                    @click.native="gotoStart()"
                />
            </div>
        <!--    <div class="col-md-4" align="center" v-show="!ProdottoCorrente.is_tailormade"> //-->
            <div class="col-md-4" align="center">
                <QQButton label="AVANTI"
                    color="blue-grey"
                    icon="mdi-arrow-right-bold"
                    size="md"
                    icon_align="right"

                    @click.native="onAvantiClicked"
                />

                <!--   :disable="isProdottoPerMedico && isTaylorMade" -->
            </div>

        </div>

        <q-dialog v-model="persistent" persistent transition-show="scale" transition-hide="scale" >
            <q-card class="bg-teal text-white" style="width: 500px">
                <q-card-section>
                <div class="text-h6">ATTENZIONE:</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    {{ MsgDialog }}
                </q-card-section>

                <q-card-actions align="right" class="bg-white text-teal">
                    <q-btn flat label="OK" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <br><br><br><br>
    </div>
</template>

<script>
    import QQProposta from "@/components/Proposte/QQProposta";
    import QQCarrello from "@/components/Proposte/QQCarrello";
    import QQButton from "@/components/QQButton";
    import commonLib from "@/libs/commonLib";
    import policy from "@/libs/policy";
    import { mapState } from "vuex";
    import { mapActions } from "vuex";
    import { mapFields } from "vuex-map-fields";
    import { mapGetters } from "vuex";

    export default {
        name: "ElencoProposte",
        data() {
            return {
                persistent: false,
                MsgDialog: "",
                isOrdered: false,
                altre_proposte_visibili: false,
                altri_dati_visibili: false,
                is_mounted: false,
                premio_selezionato: 0,
                premio_semestrale_selezionato: 0,
                provvigioni_selezionate: 0,
                importo_diritti_aby: 0,
                importo_min_consulenza: 0,
                importo_max_consulenza: 0,
                consulenza: 0
            }
        },
        components: {
            QQProposta,
            QQButton,
            QQCarrello
        },
        methods: {
            ...mapActions({
                fetchElencoDocumentiTariffa: "gestioneDocumenti/fetchElencoDocumentiTariffa"
            }),
            getElencoDocumenti(tariffa) {
                let nome_tariffa = tariffa.tariffa;
                //console.log("getElencoDocumenti()");

                //console.log("elenco_docs:",this.elenco_docs,nome_tariffa,this.elenco_docs[nome_tariffa]);

                if (!commonLib.isEmpty(this.elenco_docs[nome_tariffa])) {
                    return this.elenco_docs[nome_tariffa];
                } else
                    return {};
            },
            onCambiaValoreConsulenza(value) {
                if (commonLib.isEmpty(this.ProdottoCorrente)) return;

                this.ProdottoCorrente.consulenza = value;
            },
            onPropostaIsCheckedUpdate(proposta,dati) {
                var is_checked = dati.is_checked;

                if (commonLib.isEmpty(this.ProdottoCorrente)) return;

                this.uncheckAllProposte();
                proposta.is_checked=is_checked;

                if (!this.formPreventivo.preventivo.isQuotazioneRinnovo)
                    this.formPreventivo.IDCliente = "";

                if (!commonLib.isEmpty(this.formPreventivo.preventivo)) {
                    if (!this.formPreventivo.preventivo.isQuotazioneRinnovo)
                        this.formPreventivo.preventivo.id = "";
                }

                this.ProdottoCorrente.is_taylormade = false;
                if (this.prodottoIsSingleTailorMade) {
                    console.log("SONO QUI!!!");
                    this.ProdottoCorrente.is_taylormade = true;
                }

                // Spiana le percentuali della consulenza sul prodotto
                this.ProdottoCorrente.importo_consulenza_min = 0;
                this.ProdottoCorrente.importo_consulenza_max = 0;
                this.importo_diritti_aby = 0;
                //this.percentuale_min_consulenza = 0;
                //this.percentuale_max_consulenza = 0;
                this.importo_min_consulenza = 0;
                this.importo_max_consulenza = 0;
                this.premio_selezionato = 0;
                this.premio_semestrale_selezionato = 0;
                this.provvigioni_selezionate = 0;
                this.consulenza = 0;
                this.percentuale_aliquota_consulenza = 0;

                if (dati.provvigioni === "") dati.provvigioni = 0;

                //console.log("dati:",dati);

                if (is_checked) {
                    this.importo_diritti_aby = parseFloat(dati.diritti_aby.toFixed(2));
                    this.importo_min_consulenza = dati.importo_min_consulenza;
                    this.importo_max_consulenza = dati.importo_max_consulenza;
                    this.premio_selezionato = dati.premio;
                    this.premio_semestrale_selezionato = dati.premio_semestrale;
                    this.provvigioni_selezionate = dati.provvigioni;
                    this.consulenza = this.importo_min_consulenza + parseInt((this.importo_max_consulenza - this.importo_min_consulenza) / 2);
                    this.ProdottoCorrente.consulenza = this.consulenza;

                    this.ArrotondaImporti();

                    this.ProdottoCorrente.importo_consulenza_min = this.importo_min_consulenza;
                    this.ProdottoCorrente.importo_consulenza_max = this.importo_max_consulenza;

                    proposta.provvigioni = dati.provvigioni;


                }
            },
            uncheckAllProposte() {
                if (commonLib.isEmpty(this.ProdottoCorrente)) return;

                let Selezione = this.ProdottoCorrente.proposte.filter(proposta => {
                    return proposta.is_checked == true;
                });

                let tot = Selezione.length;

                for (let i=0;i<tot;i++) {
                    Selezione[i].is_checked = false;

                }
                this.importo_min_consulenza = 0;
                this.importo_max_consulenza = 0;
                this.consulenza = 0;
            },
            onAvantiClicked() {
                this.isOrdered = false;
                this.altre_proposte_visibili = false;
                window.scrollTo(0,0);

                if (this.proposte_selezionate.length == 0) {
                    this.MsgDialog = "Devi selezionare una proposta prima di continuare";
                    this.persistent = true;
                } else {
                    //console.log("ultimo prodotto:",this.ultimo_prodotto);
                    //console.log("Prodotto attivo:",this.ProdottoCorrente);

                    if (this.ultimo_prodotto) {
                        console.log("formPreventivo Aggiornato:",this.formPreventivo);
                        this.$emit('on-avanti-clicked');
                    } else {

                        // Aumenta il contatore dei prodotti sullo store
                        this.IndiceProdotto++;
                        //console.log("Prodotto successivo:",this.ProdottoCorrente);

                        this.aggiornaDatiProdottoSelezionato();
                    }

                }
            },
            aggiornaDatiProdottoSelezionato() {
                var prodotto_selezionato = this.ProdottoCorrente;
                this.premio_selezionato = this.importo_tariffa_selezionata;
                this.importo_diritti_aby = this.importo_diritti;
                this.importo_min_consulenza = prodotto_selezionato.importo_consulenza_min;
                this.importo_max_consulenza = prodotto_selezionato.importo_consulenza_max;

                //console.log("formPreventivo.importo_consulenza_min:",this.importo_min_consulenza);

                if ((this.importo_min_consulenza === 0) && (this.importo_max_consulenza === 0)) {
                    this.consulenza = this.importo_consulenza_minima + parseInt((this.importo_consulenza_massima - this.importo_consulenza_minima) / 2);
                    if (this.consulenza < this.importo_min_consulenza) this.consulenza = this.importo_min_consulenza;
                    if (this.consulenza > this.importo_max_consulenza) this.consulenza = this.importo_max_consulenza;
                } else {
                    this.consulenza = prodotto_selezionato.consulenza;
                }

                this.ArrotondaImporti();
            },
            decrementaIndiceProdotto() {
                let indice = this.IndiceProdotto;

                indice--;

                let Selezione = false;
                while (!Selezione) {
                    if (indice < 0) break;
                    // Selezione = this.prodotti[indice].is_checked;
                    Selezione = this.prodottiSelezionati.is_checked

                    if (!Selezione) indice--;
                }
                this.IndiceProdotto = indice;
            },
            onIndietroClicked() {
                this.isOrdered = false;
                this.altre_proposte_visibili = false;
                window.scrollTo(0,0);

                if (this.formPreventivo.preventivo.id !== "") {
                    this.$q.dialog({
                        cancel: {
                            focus: true,
                            flat: false,
                            label: "No, rimani qui",
                            color: "primary"
                        },
                        ok: {
                            color: "secondary",
                            flat: false,
                            label: "Si, crea nuovo"
                        },
                        title: 'Attenzione',
                        message: 'Tornando indietro verrà creato un nuovo preventivo. Vuoi continuare?'
                    }).onOk(() => {

                        if (!this.formPreventivo.preventivo.isQuotazioneRinnovo) {
                            this.formPreventivo.preventivo.id = "";
                            this.formPreventivo.IDCliente = "";
                        }

                        //this.IndiceProdotto--;
                        this.decrementaIndiceProdotto();

                        //console.log("Ho ridotto indice prodotto da dialog: ",this.IndiceProdotto);

                        if (this.IndiceProdotto < 0) {

                            this.$emit('on-back-clicked');
                            return;
                        }

                        this.aggiornaDatiProdottoSelezionato();
                    });
                } else {
                    this.decrementaIndiceProdotto();
                    //console.log("maremma porcello",this.IndiceProdotto);
                    if (this.IndiceProdotto < 0) {

                        this.$emit('on-back-clicked');
                        return;
                    }

                    this.aggiornaDatiProdottoSelezionato();
                }
            },
            gotoStart() {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "Non annullare",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si"
                    },
                    title: 'Attenzione',
                    message: 'Vuoi davvero annullare il preventivo?'
                }).onOk(() => {
                    this.$router.push({name : "Preventivi"});
                });
            },
            async ScaricaTuttiIDocumenti() {
                let prodotto = this.ProdottoCorrente;

                if (commonLib.isEmpty(prodotto)) {
                    //console.log("ScaricaTuttiIDocumenti prodotto is NULL");
                    return;
                }

                console.debug("ScaricaTuttiIDocumenti prodotto", prodotto.id);

                // let fetch_promises = {};

                let fetch_promises = [];

                for (let indiceProposta=0;indiceProposta<prodotto.proposte.length;indiceProposta++) {

                   // console.log("ScaricaTuttiIDocumenti proposta",prodotto.proposte[indiceProposta]);

                    let proposta = prodotto.proposte[indiceProposta];

                    let create_at = commonLib.castDateToIso(this.create_at);

                    let dati = {
                        id_tariffa: proposta.tariffa,
                        data_create_at: create_at
                    }

                    // let nome_tariffa = proposta.tariffa;
                    // fetch_promises[nome_tariffa] = this.fetchElencoDocumentiTariffa(dati);
                    fetch_promises.push(this.fetchElencoDocumentiTariffa(dati));
                    // let doc = await this.fetchElencoDocumentiTariffa(dati);

                }

                //console.log("ScaricaTuttiIDocumenti promises", fetch_promises);

                await Promise.allSettled(fetch_promises).then(link_docs => {
                    console.log("ScaricaTuttiIDocumenti resolved", link_docs);
                })
                // Object.keys(fetch_promises).forEach(nome_tariffa =>
                // {
                //     fetch_promises[nome_tariffa].resolve().then(doc =>
                //     {
                //         console.log("ScaricaTuttiIDocumenti resolved", doc);

                //         // this.elenco_docs[nome_tariffa] = doc;
                //     })
                // });
            },
            ArrotondaImporti() {
                // Calcola il valore dell'eccedenza per ottenere un premio intero senza decimali
                var premio_calcolato = Math.ceil(this.premio_selezionato + this.importo_diritti_aby + this.consulenza);
                var premio_reale     = this.premio_selezionato + this.importo_diritti_aby + this.consulenza
                var incremento = premio_calcolato - premio_reale;

                // Arrotonda consulenza minima e massima con il valore dell'incremento
                this.importo_min_consulenza += parseFloat(incremento);
                this.importo_max_consulenza += parseFloat(incremento);
                this.consulenza += parseFloat(incremento);

                var prodotto_selezionato = this.ProdottoCorrente;
                prodotto_selezionato.importo_consulenza_min = this.importo_min_consulenza;
                prodotto_selezionato.importo_consulenza_max = this.importo_max_consulenza;
            }
        },
        async mounted() {
            this.IndiceProdotto = 0;

        //    if (!commonLib.isEmpty(this.prodotti)) {
        //        this.prodotti[this.getIndiceProdotto] = this.ProdottoCorrente;
        //    }
            this.isOrdered = true;

            // Resetta i flag del formPreventivo quando si torna indietro dalla pagina della conferma
            var prodotti_attivi = this.formPreventivo.preventivo.prodotti.filter(p => {
                return p.is_checked;
            });

            var len = prodotti_attivi.length;
            for (var j=0;j<len;j++) {
                var prodotto = prodotti_attivi[j];
                var proposta_selezionata = prodotto.proposte.find(p => {
                    return p.is_checked;
                });

                if (commonLib.isEmpty(proposta_selezionata)) continue;

                if (proposta_selezionata.premio === 0) {
                    prodotto.is_taylormade = true;
                    prodotto.is_taylor_made = true;
                }
            }


            //if (!this.is_mounted) {
                await this.ScaricaTuttiIDocumenti();
            //}

            console.log("start",this.ProdottoCorrente,this.formPreventivo);

            let Selezione = this.ProdottoCorrente.proposte.filter(proposta => {
                return proposta.is_checked === true;
            });

           // console.log("stop");

            let tot = Selezione.length;

            this.importo_diritti_aby = 0;
            this.importo_min_consulenza = 0;
            this.importo_max_consulenza = 0;
            this.consulenza = 0;

            if (tot > 0) {
                this.premio_selezionato = this.importo_tariffa_selezionata;
                this.provvigioni_selezionate = this.importo_provvigioni_selezionate;
                this.importo_diritti_aby = this.importo_diritti;
                this.importo_min_consulenza = this.ProdottoCorrente.importo_consulenza_min;
                this.importo_max_consulenza = this.ProdottoCorrente.importo_consulenza_max;

                this.consulenza = this.ProdottoCorrente.consulenza;
                if ((this.importo_min_consulenza === 0) && (this.importo_max_consulenza === 0)) {
                    this.ProdottoCorrente.consulenza = 0;
                    this.consulenza = 0;
                } else {
                    this.ArrotondaImporti();
                    // console.log("Maremma porcello!!!");
                }

                for (var i=0;i<tot;i++) {
                    var proposta = Selezione[i];
                    if (proposta.provvigioni === "") proposta.provvigioni = 0;
                }
            }

          //  console.log("formPreventivo:",this.formPreventivo);

            this.is_mounted = true;

            //window.scrollTo(0,0);
        },
        async activated() {
            this.IndiceProdotto = 0;
        },
        async updated() {
            //if (!this.isOrdered) {
                this.isOrdered = true;
                await this.ScaricaTuttiIDocumenti();
            //}

            //this.isOrdered = true;
        },
        computed: {
            ...mapGetters([
                "jwtPayload"
            ]),
            operatore() { return this.jwtPayload.operatore},
            ...mapFields("formPreventivo",{
                IndiceProdotto: "IndiceProdotto",
                IndiceMinimo: "IndiceMinimo",
                IndiceMassimo: "IndiceMassimo",
                // prodotti: "preventivo.prodotti"
            }),
            ...mapFields("gestioneDocumenti",{
                elenco_docs: "elenco_docs"
            }),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                //prodotti: state => state.formPreventivo.preventivo.prodotti,
                analisi_rischio: state => state.formPreventivo.preventivo.analisi_rischio,
                formPreventivo: state => state.formPreventivo,
                preventivo: state => state.formPreventivo.preventivo,
                create_at: state => state.formPreventivo.preventivo.create_at
            }),
            isSede() {
                return policy.isSede();
            },
            ultimo_prodotto() {
                return (this.IndiceProdotto >= (this.prodottiSelezionati.length-1));
            },
            proposte_selezionate() {
                if (commonLib.isEmpty(this.ProdottoCorrente))
                    return [];

                return this.ProdottoCorrente.proposte.filter(proposta => {
                    return proposta.is_checked;
                });
            },
            ProdottoCorrente() {
                var lista_prodotti_selezionati = this.prodottiSelezionati;

               // console.log("IndiceProdotto:",this.getIndiceProdotto,this.IndiceProdotto);

                if (commonLib.isEmpty(lista_prodotti_selezionati[this.getIndiceProdotto])) return [];

                let prodotto = lista_prodotti_selezionati[this.getIndiceProdotto];
                // Ordina le proposte in base al prezzo se non è stato gia fatto
                if ((!commonLib.isEmpty(prodotto)) && (!this.isOrdered)) {
                    prodotto.proposte.sort(
                        (a,b) => (a.premio > b.premio) ? 1 : ((b.premio > a.premio) ? -1 : 0)
                    );
                }

             //   console.log("prodotto corrente:",prodotto);

                return prodotto;
            },
            getIndiceProdotto() {

               // if (this.IndiceProdotto > this.ultimo_prodotto) this.IndiceProdotto = this.ultimo_prodotto;
                if (this.IndiceProdotto < 0) return 0;
                return this.IndiceProdotto;
            },
            getTipoProdotto() {
                if (commonLib.isEmpty(this.ProdottoCorrente)) return "";

                return this.ProdottoCorrente.id;
            },
            isProdottoPerMedico() {
                let tipo_prodotto = this.getTipoProdotto;

                return tipo_prodotto === "rcp_medico";
            },
            isTaylorMade() {
                if (commonLib.isEmpty(this.ProdottoCorrente)) return false;
                return this.ProdottoCorrente.is_taylormade;
            },
            prodottoIsSingleTailorMade() {
                if (commonLib.isEmpty(this.ProdottoCorrente)) return false;
                var proposte = this.ProdottoCorrente.proposte;

                if (proposte.length === 1) {
                    // Deve controllare anche che il premio della proposta sia zero altrimenti non e` una taylormade
                    if (parseInt(proposte[0].premio)=== 0) return true;
                }

                return false;
            },
            ha_consulenza() {
                //return !(this.importo_min_consulenza === 0) && !(this.importo_max_consulenza === 0);
                return (this.importo_max_consulenza > 0);
            },
            // -----------------------------------------------------------------------------------------
            importo_consulenza_minima() {
                var consulenza = this.importo_min_consulenza;

                return consulenza;
            },
            importo_consulenza_massima() {
                var consulenza = this.importo_max_consulenza;

                return consulenza;
            },
            // -----------------------------------------------------------------------------------------
            importo_premio_garantito() {
                return this.premio_selezionato +
                       this.importo_diritti_aby +
                       this.consulenza;
            },
            importo_tariffa_selezionata() {
                if (commonLib.isEmpty(this.ProdottoCorrente)) return 0;
                if (commonLib.isEmpty(this.ProdottoCorrente.proposte)) return 0;

                var proposte = this.ProdottoCorrente.proposte;
                var premio = 0;
                for (var i=0;i<proposte.length;i++) {
                    var proposta = proposte[i];
                    if (proposta.is_checked) {
                        premio = proposta.premio;
                    }
                }

                if ((isNaN(premio)) || (premio === "")) premio = 0.00;

                return parseFloat(premio);
            },
            importo_provvigioni_selezionate() {
                if (commonLib.isEmpty(this.ProdottoCorrente)) return 0;
                if (commonLib.isEmpty(this.ProdottoCorrente.proposte)) return 0;

                var proposte = this.ProdottoCorrente.proposte;
                var premio = 0;
                for (var i=0;i<proposte.length;i++) {
                    var proposta = proposte[i];
                    if (proposta.is_checked) {
                        premio = proposta.provvigioni;
                    }
                }

                if ((isNaN(premio)) || (premio === "")) premio = 0.00;

                return parseFloat(premio);
            },
            importo_diritti() {

                //console.log("prodotto corrente:",this.ProdottoCorrente);

                if (commonLib.isEmpty(this.ProdottoCorrente)) return 0;
                if (commonLib.isEmpty(this.ProdottoCorrente.proposte)) return 0;

                var proposte = this.ProdottoCorrente.proposte;
                var premio = 0;
                for (var i=0;i<proposte.length;i++) {
                    var proposta = proposte[i];
                    if (proposta.is_checked) {
                        //console.log("proposta selezionata:",proposta);
                        premio = proposta.diritti_aby;
                    }
                }

                if ((isNaN(premio)) || (premio === "")) premio = 0.00;

                return parseFloat(premio);
            },
            importo_provvigioni_consulenza_collaboratore() {
                if (commonLib.isEmpty(this.ProdottoCorrente)) return 0;
                if (commonLib.isEmpty(this.ProdottoCorrente.proposte)) return 0;

                var proposta = this.ProdottoCorrente.proposte[0];
                var aliquota_consulenza_collaboratore = proposta.aliquota_consulenza_collaboratore;
                var importo = this.consulenza * aliquota_consulenza_collaboratore / 100;

                return importo;
            },
            importo_provvigioni_consulenza_aby()  {
                var importo = this.consulenza - this.importo_provvigioni_consulenza_collaboratore;

                return importo.toFixed(2);
            },
            provvigioni_totali() {
                return (parseFloat(this.importo_provvigioni_consulenza_collaboratore) +
                        parseFloat(this.importo_provvigioni_selezionate)).toFixed(2);
            },
            premio_totale() {
                var totale = parseFloat(this.importo_tariffa_selezionata) +
                             parseFloat(this.importo_diritti) +
                             parseFloat(this.consulenza);

                return totale.toFixed(2);
            },
            prodottiSelezionati() {
                return this.formPreventivo.preventivo.prodotti.filter(prodotto => {
                    return prodotto.is_checked;
                });
            }
        }
    }

</script>

<style scoped>

</style>
