<template>
    <div class="ConfermaPreventivi q-pa-md" align="center" ref="scrollArea" v-show="is_mounted">

        <span class="text-h4">Conferma Proposte</span>
        <hr>
        <br>
        <span class="text-h5 q-py-md">PROPOSTE SELEZIONATE</span>
        <br><br>

        <table class='table'>
        <thead>
            <tr>
                <th>Compagnia</th>
                <th>Tariffa</th>
                <th>Parametri</th>
                <th width="180">Premio preventivo</th>
                <th width="400">Consulenza</th>
                <th width="180">Costo acquisto garantito</th>
            </tr>
        </thead>
        <tbody v-for="(prodotto, index) in getProdottiAttivi"
            v-bind:key="index">

            <tr v-for="(proposta, indexProposta) in prodotto.proposte.filter(p => {return p.is_checked})"
                v-bind:key="indexProposta">
                <td>{{ proposta.compagnia }}</td>
                <td>{{ proposta.tariffa}} <br>{{ proposta.label }}</td>
                <td>
                    <table style="border-collapse: collapse;">
                        <tbody>
                            <tr v-for="(garanzia, indexGaranzia) in proposta.parametri"
                            v-bind:key="indexGaranzia"
                            v-bind="garanzia">
                                <td style=" border: none; border-bottom: 1px SOLID #000;">{{garanzia.label}}</td>
                                <td style=" border: none; border-bottom: 1px SOLID #000;">{{ analizzaRisposta(garanzia) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td align="right">{{ getPremioProposta(proposta) }}</td>
                <td style="padding-left: 18px;">
                    <br><br>

                    <q-slider
                        color="red"
                        :readonly="!is_quotazione"
                        :disabled="!is_quotazione"
                        label-always
                        :label-value="dati_consulenza[getIndiceProdotto(prodotto)].consulenza.toFixed(2) + '€'"
                        v-model="dati_consulenza[getIndiceProdotto(prodotto)].consulenza"
                        :min="dati_consulenza[getIndiceProdotto(prodotto)].importo_min_consulenza"
                        :max="dati_consulenza[getIndiceProdotto(prodotto)].importo_max_consulenza"
                        :inner-min="dati_consulenza[getIndiceProdotto(prodotto)].importo_min_consulenza"
                        :inner-max="dati_consulenza[getIndiceProdotto(prodotto)].importo_max_consulenza"
                        @change="onCambiaValoreConsulenza"
                    />
                    <!--
                        :readonly="!is_quotazione"
                        :disabled="!is_quotazione"
                    -->
                </td>
                <td align="right">{{ CostoAcquistoGarantito(getIndiceProdotto(prodotto)) }}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="3" align="right"><strong>TOTALE</strong></td>
                <td align="right"><strong>&euro; {{ getTotaleCarrello }}</strong></td>
                <td>

                </td>
                <td align="right"><strong>&euro; {{ getTotaleAcquistoGarantito }}</strong></td>
            </tr>
        </tfoot>
        </table>
        <br>

        <div v-if="domande_analisi_necessita.length > 0">
            <span class="text-h5 q-py-md">ANALISI NECESSIT&Agrave;</span>
            <fieldset>
                <QQVisualizzaQuestionario :domande="domande_analisi_necessita" />
            </fieldset>
            <br>

        </div>

        <div v-if="domande_analisi_rischio.length > 0">
            <span class="text-h5 q-py-md">ANALISI DEL RISCHIO</span>

            <div v-for="(Domanda, index) in domande_analisi_rischio"
                 v-bind:key="index">
                 <fieldset>
                     <legend><strong>{{ Domanda.label }}</strong></legend>
                     <QQVisualizzaQuestionario :domande="Domanda.domande" :analisi_necessita="domande_analisi_necessita" />
                 </fieldset>
                 <br>
            </div>
        </div>
        <br>

        <span class="text-h5 q-py-md">PRODOTTI IDENTIFICATI</span>
        <br><br>
        <table class='table'>
        <thead>
            <tr>
                <th>Tipo di Prodotto</th>
                <th>Prodotto</th>
                <th>Selezionato</th>
                <th>Motivo rinuncia</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(prodotto, index) in this.prodotti_suggeriti_consigliati"
                v-bind:key="index">
                <td>{{ tipoProdotto(prodotto.is_consigliato) }}</td>
                <td>{{ prodotto.label }}</td>
                <td align="center">
                    <q-icon name="mdi-check" v-show="prodotto.is_checked" style="font-size: 32px;" />
                </td>
                <td>{{ prodotto.motivo_rinuncia }}</td>
            </tr>
        </tbody>
        </table>
        <br>

        <br><hr>
        <div class="row justify-center">

            <div class="col-md-3" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-md-3" align="center">
                <QQButton label="ANNULLA PREVENTIVO" color="blue-grey" icon="mdi-close-octagon" size="md"
                    @click.native="gotoStart()"
                />
            </div>

            <div class="col-md-3" align="center">
                <QQButton label="Registra preventivo"
                    color="blue-grey"
                    icon="save"
                    size="md"
                    :disabled="!pulsante_abilitato"
                    @click.native="onRegistraClicked"
                />
            </div>
            <div class="col-md-3" align="center">
                <QQButton label="Avanti" color="blue-grey" icon="mdi-arrow-right-bold" size="md" :disabled="!pulsante_abilitato"
                    @click.native="onAvantiClicked"
                />
            </div>
        </div>

        <q-dialog v-model="persistent" persistent transition-show="scale" transition-hide="scale" >
            <q-card class="bg-teal text-white" style="width: 500px">
                <q-card-section>
                <div class="text-h6">ATTENZIONE:</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    {{ MsgDialog }}
                </q-card-section>

                <q-card-actions align="right" class="bg-white text-teal">
                    <q-btn flat label="OK" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <br><br><br><br>

    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import QQButton from "@/components/QQButton";
    import { mapFields } from "vuex-map-fields";
    import commonLib from "@/libs/commonLib";
    import QQVisualizzaQuestionario from "@/components/QQVisualizzaQuestionario"

    export default {
        name: "ConfermaProposte",
        data() {
            return {
                persistent: false,
                MsgDialog: "",
                pulsante_abilitato: true,
                is_mounted: false,
                is_quotazione: false,
                dati_consulenza: [
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    },
                    {
                        importo_min_consulenza: 0,
                        importo_max_consulenza: 0,
                        consulenza: 0,
                        diritti_aby: 0,
                        quota_consulenza_unita_operativa: 0,
                        provvigioni_totali: 0
                    }
                ]
            }
        },
        components: {
            QQButton,
            QQVisualizzaQuestionario
        },
        computed: {
            ...mapFields("formPreventivo",{
                IndiceProdotto: "IndiceProdotto"
                //IndiceMinimo: "IndiceMinimo",
                //IndiceMassimo: "IndiceMassimo"
            }),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                domande_analisi_necessita: state => state.formPreventivo.preventivo.analisi_necessita,
                domande_analisi_rischio: state => state.formPreventivo.preventivo.analisi_rischio,
                prodotti_suggeriti_consigliati: state => state.formPreventivo.preventivo.prodotti,
                prodotti: state => state.formPreventivo.preventivo.prodotti
            }),
        /*    importo_consulenza_minima() {
                var consulenza = this.importo_min_consulenza;

                return consulenza;
            },
            importo_consulenza_massima() {
                //var consulenza = this.importo_max_consulenza;

                return 250; //consulenza;
            }, */
            getProdottiAttivi() {
                return this.prodotti.filter(prodotto => {
                    return prodotto.is_checked;
                });
            },
            getProposteAttive() {
                var prodotti = this.getProdottiAttivi;
                var prodotti_filtrati = prodotti.filter(prodotto => {
                    return prodotto.proposte.filter(proposta => {
                        return proposta.is_checked;
                    });
                });

                return prodotti_filtrati.proposte;
            },
            getTotaleCarrello() {
                let tot = this.prodotti.length;
                let ImportoTotale = 0;

                for (let i=0;i<tot;i++) {
                    let proposte = this.prodotti[i].proposte;
                    for (let j=0;j<proposte.length;j++) {
                        if (this.prodotti[i].proposte[j].is_checked) {
                            ImportoTotale += parseFloat(this.prodotti[i].proposte[j].premio.toFixed(2));
                        }
                    }
                }

                return Math.round(ImportoTotale).toFixed(2);
            },
            getTotaleAcquistoGarantito() {
                let premio_totale = 0;
                let diritti_aby = 0;
                let tot = this.prodotti.length;
                let consulenza = 0;

                for (let i=0;i<tot;i++) {
                    var prodotto = this.prodotti[i];

                    if (!prodotto.is_checked) continue;

                    consulenza += parseFloat(prodotto.consulenza.toFixed(2));

                    for (var j=0;j<prodotto.proposte.length;j++) {
                        let proposta = prodotto.proposte[j];

                        if (!proposta.is_checked) continue;

                        premio_totale += parseFloat(proposta.premio.toFixed(2));
                        diritti_aby += parseFloat(proposta.diritti_aby.toFixed(2));
                    }
                }

                premio_totale += parseFloat(consulenza) + parseFloat(diritti_aby);
                console.log("totale:",premio_totale,consulenza,diritti_aby);

                return premio_totale.toFixed(2);
            }
        },
        async mounted() {
            //console.log("MOUNTED",this.prodotti);
            window.scrollTo(0,0);

            console.log("formPreventivo ricevuto:",this.formPreventivo);

            //var guid_preventivo = this.formPreventivo.preventivo.id;

            for (let i = 0;i < this.prodotti.length; i++) {
                let prodotto = this.prodotti[i];

                if (! prodotto.is_checked) continue;

                for (let j=0;j<prodotto.proposte.length;j++) {
                    let proposta = prodotto.proposte[j];

                    if ((!commonLib.isEmpty(proposta)) && (!proposta.is_checked)) continue;

                    // console.log("proposta:",proposta);

                /*    let dati_economici = await this.fetchDatiEconomiciPreventivo(
                        {
                            guidPreventivo:guid_preventivo,
                            prodotto_id_tariffa: prodotto.id,
                            premio_tariffa: proposta.premio,
                            provvigioni_tariffa: proposta.provvigioni
                        }
                    ) */

                    // Recupera i dati economici dal formpreventivo
                    var dati_economici = {
                        importo_minimo_consulenza: prodotto.importo_consulenza_min,
                        importo_massimo_consulenza: prodotto.importo_consulenza_max,
                        importo_diritti_aby: proposta.diritti_aby,
                        consulenza: prodotto.consulenza
                    }

                    //console.log("dati_economici:",dati_economici);

                    this.is_quotazione = true;
                    //if (parseFloat(dati_economici.importo_massimo_consulenza) === 0) this.is_quotazione = true;
                    //if ((parseFloat(dati_economici.importo_massimo_consulenza) > 0) && (parseFloat(dati_economici.consulenza) === 0)) this.is_quotazione = true;

                    var consulenza = prodotto.consulenza;
                    if (consulenza === 0) consulenza = dati_economici.consulenza;

                    // Aggiorna il formPreventivo
                    this.prodotti[i].importo_consulenza_min = parseFloat(dati_economici.importo_minimo_consulenza);
                    this.prodotti[i].importo_consulenza_max = parseFloat(dati_economici.importo_massimo_consulenza);
                    this.prodotti[i].consulenza = parseFloat(consulenza);
                    proposta.diritti_aby = parseFloat(dati_economici.importo_diritti_aby);

                    this.dati_consulenza[i].importo_min_consulenza = parseFloat(dati_economici.importo_minimo_consulenza);
                    this.dati_consulenza[i].importo_max_consulenza = parseFloat(dati_economici.importo_massimo_consulenza);
                    this.dati_consulenza[i].consulenza = parseFloat(consulenza);
                    this.dati_consulenza[i].diritti_aby = parseFloat(dati_economici.importo_diritti_aby);

                    var totale = proposta.premio + this.dati_consulenza[i].consulenza + this.dati_consulenza[i].diritti_aby;
                    if (totale !== Math.round(totale)) {
                        var premio = parseFloat(proposta.premio);
                        var premio_calcolato = Math.ceil(premio + parseFloat(this.dati_consulenza[i].diritti_aby) + parseFloat(this.dati_consulenza[i].consulenza));
                        var premio_reale = premio + parseFloat(this.dati_consulenza[i].diritti_aby) + parseFloat(this.dati_consulenza[i].consulenza);
                        var incremento = premio_calcolato - premio_reale;
                        this.dati_consulenza[i].consulenza += incremento;
                        this.dati_consulenza[i].importo_min_consulenza += incremento;
                        this.dati_consulenza[i].importo_max_consulenza += incremento;
                    }

                    console.log("dati consulenza:",this.dati_consulenza);

                    //console.log("dati economici:",dati_economici);
                }
            }

            this.onCambiaValoreConsulenza();


            // Scansiona tutti i prodotti attivi solo se nel formPreventivo non sono gia stati settati
       /*    if ( this.prodotti[0].importo_consulenza_max === 0) {
                this.is_quotazione = true;

                for (let i = 0;i < this.prodotti.length; i++) {
                    let prodotto = this.prodotti[i];

                    if (! prodotto.is_checked) continue;

                    for (let j=0;j<prodotto.proposte.length;j++) {
                        let proposta = prodotto.proposte[j];

                        if ((!commonLib.isEmpty(proposta)) && (!proposta.is_checked)) continue;

                        let dati_economici = await this.fetchDatiEconomiciPreventivo(
                            {
                                prodotto_id_tariffa: prodotto.id,
                                premio_tariffa: proposta.premio,
                                provvigioni_tariffa: proposta.provvigioni
                            }
                        )

                        console.log("dati_economici:",dati_economici);

                        // Aggiorna il formPreventivo
                        this.prodotti[i].importo_consulenza_min = parseFloat(dati_economici.importo_minimo_consulenza);
                        this.prodotti[i].importo_consulenza_max = parseFloat(dati_economici.importo_massimo_consulenza);
                        this.prodotti[i].consulenza = parseFloat(dati_economici.consulenza);
                        proposta.diritti_aby = parseFloat(dati_economici.importo_diritti_aby);

                        this.dati_consulenza[i].importo_min_consulenza = parseFloat(dati_economici.importo_minimo_consulenza);
                        this.dati_consulenza[i].importo_max_consulenza = parseFloat(dati_economici.importo_massimo_consulenza);
                        this.dati_consulenza[i].consulenza = parseFloat(dati_economici.consulenza);
                        this.dati_consulenza[i].diritti_aby = parseFloat(dati_economici.importo_diritti_aby);

                        console.log("dati economici:",dati_economici);
                    }

                    console.log("this.prodotti:",this.prodotti);

                }
            } else {
                console.log("sono qui");
                this.is_quotazione = false;

                for (let i = 0;i < this.prodotti.length; i++) {
                    let prodotto = this.prodotti[i];

                    if (! prodotto.is_checked) continue;

                    for (let j=0;j<prodotto.proposte.length;j++) {
                        let proposta = prodotto.proposte[j];

                        if ((!commonLib.isEmpty(proposta)) && (!proposta.is_checked)) continue;

                        this.dati_consulenza[i].importo_min_consulenza = parseFloat(prodotto.importo_consulenza_min);
                        this.dati_consulenza[i].importo_max_consulenza = parseFloat(prodotto.importo_consulenza_max);
                        this.dati_consulenza[i].consulenza = parseFloat(prodotto.consulenza);
                        this.dati_consulenza[i].diritti_aby = parseFloat(proposta.diritti_aby);
                    }

                }
            } */

            this.is_mounted = true;
        },
        async activated() {

        },
        methods: {
            ...mapActions({
                fetchDatiProspect: "formPreventivo/fetchDatiProspect",
                isQuotazione: "gestioneQuotazioni/isQuotazione",
                fetchDatiEconomiciPreventivo: "formPreventivo/fetchDatiEconomiciPreventivo",
                fetchDatiPreventivo: "formPreventivo.fetchDatiPreventivo"
                //fetchFormPreventivo: "formPreventivo/fetchFormPreventivo"
            }),
            onCambiaValoreConsulenza() {
                // Aggiorna il valore di consulenza su tutti i prodotti
                for (let i=0;i<this.prodotti.length;i++) {
                    var prodotto = this.prodotti[i];

                    if (!prodotto.is_checked) continue;

                    console.log("dati consulenza slider:",this.dati_consulenza[i].consulenza);

                    prodotto.consulenza = parseFloat(this.dati_consulenza[i].consulenza);
                }
            },
            getIndiceProdotto(prodotto) {
                var indice = -1;
                for (var i=0;i<this.formPreventivo.preventivo.prodotti.length;i++) {
                    if (this.formPreventivo.preventivo.prodotti[i].id === prodotto.id) indice = i;
                }

                //console.log("indice prodotto:",indice);

                return indice;
            },
            attivaPulsanti() {
                this.pulsante_abilitato = true;
            },
            disattivaPulsanti() {
                this.pulsante_abilitato = false;
                 setTimeout(()=>{
                    this.attivaPulsanti()
                }, 10000);
            },
            getPremioProposta(proposta) {
                var premio = parseFloat(proposta.premio);
                if (premio === 0.00) {
                    return "DA QUOTARE";
                }

                return "€ "+premio.toFixed(2);
            },
        /*    CostoAcquistoGarantito(prodotto,proposta) {
                var consulenza = prodotto.consulenza;
                var premio = Math.round(proposta.premio);
                var diritti_aby = proposta.diritti_aby;

                var totale = consulenza + premio + diritti_aby;

                return "€ "+ totale.toFixed(2);
            }, */
            CostoAcquistoGarantito(indiceProdotto) {
                //console.log("COSTO ACQUISTO GARANTITO");
                var consulenza = parseFloat(this.dati_consulenza[indiceProdotto].consulenza.toFixed(2));
                var premio = 0;
                var diritti_aby = 0;

                console.log("Maremma dati consulenza:",this.dati_consulenza);
                console.log("Maremma tipo prodotto:",this.prodotti[indiceProdotto].id);

                for (var i=0;i<this.prodotti[indiceProdotto].proposte.length;i++) {
                    let proposta = this.prodotti[indiceProdotto].proposte[i];

                    // console.log("proposta:",proposta,consulenza);

                    if (!proposta.is_checked) continue;

                    premio      = parseFloat(proposta.premio.toFixed(2));
                    diritti_aby = parseFloat(proposta.diritti_aby.toFixed(2));
                }
                // Aggiusta il valore della consulenza per arrotondare l'importo
            //    var premio_calcolato = Math.ceil(parseFloat(premio) + parseFloat(consulenza) + parseFloat(diritti_aby));
            //    var premio_reale = parseFloat(premio) + parseFloat(consulenza) + parseFloat(diritti_aby);
            //    var incremento = premio_calcolato - premio_reale;

            //    if (Math.trunc(consulenza) === consulenza) {
            //        consulenza += parseFloat(incremento);
            //    }

                console.log("premio,consulenza,diritti:",premio,consulenza,diritti_aby);

                var totale = Math.round(parseFloat(premio) + parseFloat(consulenza) + parseFloat(diritti_aby));

                return "€ "+ totale.toFixed(2);
            },
            getIndiceUltimoProdottoAttivo() {
                var indice = 0;
                var len = this.formPreventivo.preventivo.prodotti.length;

                for (indice = len-1; indice > 0; indice--) {
                    if (this.formPreventivo.preventivo.prodotti[indice].is_checked) {
                        return indice;
                    }
                }

                return indice;
            },
            onIndietroClicked() {
                if (this.formPreventivo.preventivo.id !== "") {
                    this.$q.dialog({
                        cancel: {
                            focus: true,
                            flat: false,
                            label: "No, rimani qui",
                            color: "primary"
                        },
                        ok: {
                            color: "secondary",
                            flat: false,
                            label: "Si, crea nuovo"
                        },
                        title: 'Attenzione',
                        message: 'Tornando indietro verrà creato un nuovo preventivo. Vuoi continuare?'
                    }).onOk(() => {
                        this.formPreventivo.preventivo.id = "";
                        this.IndiceProdotto = this.getIndiceUltimoProdottoAttivo();
                        this.$emit('on-back-clicked');
                    });
                } else {
                    this.IndiceProdotto = this.getIndiceUltimoProdottoAttivo();
                    this.$emit('on-back-clicked');
                }

            },
            onAvantiClicked() {
                this.disattivaPulsanti();
                this.$emit('on-avanti-clicked',true);
            },
            async onRegistraClicked() {
                this.pulsante_abilitato = false;
                this.disattivaPulsanti();
                await this.fetchDatiProspect();
                this.$emit('on-avanti-clicked');
            },
            tipoProdotto(is_consigliato) {
                if (is_consigliato) {
                    return "Consigliato";
                } else {
                    return "Da catalogo prodotti";
                }
            },
            analizzaRisposta(parametro) {
                let result = "";

                if (parametro.type === "checkbox") {
                    return parametro.is_checked ? "Si" : "No";
                }

                var value = parametro.value;
                var options = parametro.options;

                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                })
                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);
                    return result.replace("_"," ");
                } else {
                    if (value !== null) {
                        return value.replace("_"," ");
                    } else
                        return "";
                }
            },
            gotoStart() {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "Non annullare",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si"
                    },
                    title: 'Attenzione',
                    message: 'Vuoi davvero annullare il preventivo?'
                }).onOk(() => {
                    this.$router.push({name : "Preventivi"});
                });

            }
        }
    }

</script>

<style scoped>
    .table {
        border-collapse: collapse;
        width: 100%;
    }

    .table td, .table th {
        padding: 8px;
        margin-bottom: 6px;
        border: 1px SOLID #C0C0C0;
    }

    .table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        color: white;
        background: -moz-linear-gradient(top, rgba(30,87,153,1) 0%, rgba(117,177,226,0.7) 92%, rgba(125,185,232,0) 100%);
        background: -webkit-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(117,177,226,0.7) 92%,rgba(125,185,232,0) 100%);
        background: linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(117,177,226,0.7) 92%,rgba(125,185,232,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#007db9e8',GradientType=0 );
    }

</style>
