<template>
    <!-- ========================================================
        La pagina visualizza il questionario generico per
        una prima identificazione dei prodotti su cui fare
        il calcolo del premio. Il questionario viene restituito
        da backend. Una volta che le domande sono state
        compilate, il JSON viene restituito al backend.
         ======================================================== //-->
    <div class="MenuPrincipale q-pa-md" v-if="is_mounted">
        <!-- ========================================================================
            il DIV sotto si occupa di visualizzare il titolo del menu utilizzando la
            variabile titolo_area recuperato dallo store
        ======================================================================== //-->
        <div align="center">
            <span class="text-h4">Analisi delle Necessit&agrave;</span>
            <hr style='color: #fff;'>
        </div>

        <div class="text-center justify-center q-pa-md q-gutter-sm"
            v-show="!is_loading">

            <q-spinner-hourglass
                size="4em"
            />

        </div>

        <div align="center" v-show="is_loading">
            <QQQuestionario
                :domande="preventivo.analisi_necessita"
                v-model="preventivo.analisi_necessita"
                @error-checked="onErrorChecked($event)" />
            <hr style='color: #fff;'>
        </div>

        <div class="row justify-center">
            <div class="col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="gotoStart()"
                />
            </div>
            <div class="col-md-4" align="center">
                <QQButton label="AVANTI"
                    color="blue-grey"
                    icon="mdi-arrow-right-bold"
                    size="md"
                    icon_align="right"
                    @click.native="onAvantiClicked"
                />
            </div>
        </div>

        <q-dialog v-model="persistent"
            persistent transition-show="scale"
            transition-hide="scale" >
            <q-card class="bg-teal text-white" style="width: 500px">
                <q-card-section>
                <div class="text-h6">ATTENZIONE:</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    Il questionario non risulta compilato
                    correttamente. Verifica prima di continuare
                </q-card-section>

                <q-card-actions align="right" class="bg-white text-teal">
                <q-btn flat label="OK" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <br><br><br><br>
    </div>

</template>

<script>

import QQQuestionario from '@/components/QQQuestionario.vue';
import QQButton from '@/components/QQButton.vue';
import api from '@/libs/api.js';
import commonLib from "@/libs/commonLib"
import {mapFields} from "vuex-map-fields"
import { mapState, mapActions } from "vuex";

export default {
    name: "AnalisiNecessita",
    components: {
        QQQuestionario,
        QQButton
    },
    data() {
        return {
            persistent: false,
            have_errors: false,
            is_mounted: false
        }
    },
    /* =======================================================================
        computed permette di associare ad una variabile il dato restituito
        da una funzione
       ======================================================================= */
    computed: {
       ...mapFields("formPreventivo", {
            preventivo: "preventivo",
            is_loading: "is_loading"
        }),
        ...mapState({
            area: state => state.area,
            titolo_area: state => state.titolo_area,
        }),
        getDomande() {
            if (commonLib.isEmpty(this.preventivo.analisi_necessita)) {
                return [];
            }

            return this.preventivo.analisi_necessita;
        }
    },

    methods: {
        onErrorChecked(errorList) {
            let errori = false;
            errorList.forEach(errore => {
                errori = errori || errore;
            })

            this.have_errors = errori;
        },
        async onAvantiClicked() {
            this.bShowMessage = true;

            // Controlla che tutte le domande siano state compilate
            let statoValidazione = await commonLib.controllaSeQuestionarioCompilato(this.preventivo.analisi_necessita);

            console.log("statoValidazione:",statoValidazione);

            if(!statoValidazione) {
                this.persistent = true;
            } else {

                let oJSon = await this.postAnalisiNecessita();

                //if (!commonLib.isEmpty(oJSon.preventivo)) {
                if (!commonLib.isEmpty(this.preventivo.prodotti)) {

                    // copia il prodotto selezionato in quello ricevuto
                    let prodotto_selezionato = oJSon.data.preventivo.prodotti.filter(prod => {
                        //prod.is_checked = false;

                        return prod.id == this.preventivo.prodotti[0].id;
                    });
                    prodotto_selezionato[0].is_checked = true;
                }

                this.$store.state.formPreventivo.preventivo = oJSon.data.preventivo;
                this.$emit('on-avanti-clicked');
            }
        },
        async postAnalisiNecessita() {
            var url = process.env.VUE_APP_API_URL + 'formPreventivo/analisiNecessita?area='+this.area;
            let response = {
                preventivo: this.preventivo
            }
            //return await api.postJSonToController(this.area + "/preventivi",response,"domande")
            var result = await api.postJSonToController(url,response);

            return result;
        },
        ...mapActions({
            fetchDomandeQuestionario: "formPreventivo/fetchDomandeQuestionario"
        }),
        /* ==========================================================
        La funzione recupera dall'URL il codice del tipo di menu
        da visualizzare dopo la chiamata alla pagina principale
        ==========================================================*/
        getMenuTypeFromUrl() {
            var urlStr = window.location.href;
            var miourl = new URL(urlStr);
            var Menu = miourl.searchParams.get("menu");

            return Menu;
        },
        gotoStart() {
            this.$q.dialog({
                cancel: {
                    focus: true,
                    flat: false,
                    label: "Non annullare",
                    color: "primary"
                },
                ok: {
                    color: "secondary",
                    flat: false,
                    label: "Si"
                },
                title: 'Attenzione',
                message: 'Vuoi davvero annullare il preventivo?'
            }).onOk(() => {
                this.$router.push({name : "Preventivi"});
            });
        },
    },
    async mounted() {
        await this.fetchDomandeQuestionario();
        this.is_mounted = true;
    },
    async activated() {
        await this.fetchDomandeQuestionario();
    }

}
</script>

<style scoped>

</style>

