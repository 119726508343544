<template>
    <div class="VisualizzaQuestionario justify-center q-pa-md">

        <table class="table">
            <tbody>
                <tr v-for="(Domanda, index) in domande"
                            v-bind:key="index" v-show="isDomandaVisible(Domanda)">
                    <td v-html="Domanda.label"></td>
                    <td>{{ analizzaRisposta(Domanda.value,Domanda.options) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import commonLib from "@/libs/commonLib";
    export default {
        name: "QQVisualizzaQuestionario",
        data() {
            return {

            }
        },
        props: {
            domande: {
                type: Array,
                required: true
            },
            analisi_necessita: {
                type: Array,
                required: false,
                default: function() {
                    return [];
                }
            }
        },
        methods: {
            isDomandaVisible(domanda) {

                var esito = commonLib.isDomandaVisible(this.domande,domanda);
                if (esito === false) {
                    esito = commonLib.isDomandaVisible(this.analisi_necessita,domanda);
                }
                //return esito;
                return true;
            },
            analizzaRisposta(value,options) {
                let result = "";

                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                })
                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);
                    return result.replace("_"," ");
                } else {
                    if (value !== null) {
                        return value.replace("_"," ");
                    } else
                        return "";
                }
            }
        }
    }
</script>

<style scoped>
    .col-md-6 {
        border: 1px SOLID #808080;
        text-align: left;
        padding: 6px;
        font-size: 14px;
    }
    strong {
        color: #404040;
    }
    .table {
        border-collapse: collapse;
        width: 100%;
        border: 0px solid #404040;
    }

    .table td, .table th {
        padding: 8px;
        margin-bottom: 6px;
        border: 1px SOLID #C0C0C0;
    }

    .table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        color: white;
        background: -moz-linear-gradient(top, rgba(30,87,153,1) 0%, rgba(117,177,226,0.7) 92%, rgba(125,185,232,0) 100%);
        background: -webkit-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(117,177,226,0.7) 92%,rgba(125,185,232,0) 100%);
        background: linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(117,177,226,0.7) 92%,rgba(125,185,232,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#007db9e8',GradientType=0 );
    }

</style>
