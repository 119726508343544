<template>
    <div class="SelezioneProdotti" ref="SelezioneProdotti">

        <div align="center">
            <span class="text-h4">Selezione prodotti</span>
            <hr style='color: #fff;'>
        </div>

        <div class="text-center justify-center q-pa-md q-gutter-sm"
            v-show="!is_loading">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="fixed-center"
            v-show="!is_questionario_loaded">

            <q-spinner-hourglass
                size="4em"
            />

        </div>

        <QQSceltaProdotti :pulsanti="getListaPulsanti()" v-model="values" :domande_rinuncia="pulsanti.motivi_rinuncia" />

        <q-dialog v-model="persistent" persistent
            transition-show="scale" transition-hide="scale" >
            <q-card class="bg-teal text-white" style="width: 500px">
                <q-card-section>
                <div class="text-h6">ATTENZIONE:</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    Devi selezionare almeno un prodotto dalla lista
                </q-card-section>

                <q-card-actions align="right" class="bg-white text-teal">
                <q-btn flat label="OK" v-close-popup @click.native="ModificaRisposta"/>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <div class="row justify-center">
            <div class="col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="$emit('on-back-clicked')"
                />
            </div>
            <div class="col-md-4" align="center">
                <QQButton label="ANNULLA PREVENTIVO" color="blue-grey" icon="mdi-close-octagon" size="md"
                    @click.native="gotoStart()"
                />
            </div>
            <div class="col-md-4" align="center">
                <QQButton label="AVANTI"
                    color="blue-grey"
                    icon="mdi-arrow-right-bold"
                    size="md"
                    icon_align="right"
                    @click.native="onAvantiClicked"
                />
            </div>
        </div>
        <br><br><br><br>
    </div>
</template>

<script>

import QQButton from '@/components/QQButton.vue';
import QQSceltaProdotti from '@/components/QQSceltaProdotti.vue';
import api from '@/libs/api';
import commonLib from '@/libs/commonLib';
import { mapState } from "vuex";

export default {
    name: "SelezioneProdotti",
    components: {
        QQButton,
        QQSceltaProdotti
    },
    data() {
        return {
            values: [],
            persistent: false,
            is_questionario_loaded: true
        }
    },
    computed: {
        ...mapState({
            area: state => state.area,
            titolo_area: state => state.titolo_area,
            pulsanti: state => state.formPreventivo.preventivo
        }),
        pulsanti_selezionati() {
            return this.pulsanti.prodotti.filter(pulsante => pulsante.is_checked);
        },
        is_loading() {
            let stato = commonLib.isEmpty(this.pulsanti);

            return !stato;
        },
    },
    methods: {
        getListaPulsanti() {
            return this.pulsanti.prodotti;
        },
        async onAvantiClicked() {
            this.persistent = false;

            if(!this.pulsanti_selezionati.length) {
                this.persistent = true;
                return;
            }

            this.is_questionario_loaded = false;
            //this.$refs.SelezioneProdotti.classList.add("dimmed");

            let oJSon = await this.postSelezioneProdotti();

            //this.$refs.SelezioneProdotti.classList.remove("dimmed");
            this.is_questionario_loaded = true;

            // Memorizza le domande sullo store
            //this.$store.commit("gestioneQuestionarioSpecifico/setDomande",oJSon);

            this.$store.commit("formPreventivo/updateDomande",oJSon.data.preventivo);

            this.$emit("on-avanti-clicked");
        },
        async postSelezioneProdotti() {
            let url = process.env.VUE_APP_API_URL + 'formPreventivo/prodotti?area='+this.area;
            let result = {
                preventivo: this.pulsanti
            }
            //let oJSon =  await api.postJSonToController(this.area + "/preventivi",result,"pulsanti")
            let oJSon = await api.postJSonToController(url,result);

            return oJSon;
        },
        ModificaRisposta() {

        },
        gotoStart() {
           this.$q.dialog({
                cancel: {
                    focus: true,
                    flat: false,
                    label: "Non annullare",
                    color: "primary"
                },
                ok: {
                    color: "secondary",
                    flat: false,
                    label: "Si"
                },
                title: 'Attenzione',
                message: 'Vuoi davvero annullare il preventivo?'
            }).onOk(() => {
                this.$router.push({name : "Preventivi"});
            });
        },

    },
    mounted() {
        window.scrollTo(0,0);
    },
}
</script>

<style scoped>

</style>
