<template>
    <!-- ================================================================
        Il componente si occupa di visualizzare una singola proposta
        visualizzando una apposita CARD contenente il premio e i parametri
        che l'operatore può modificare per adattare il preventivo alle
        necessità del cliente.
         ================================================================ //-->
<!--    <div class="QQProposte q-pa-md items-start q-gutter-md" align="center" v-show="proposta_visible"> //-->
    <div class="QQProposte q-pa-md items-start q-gutter-md" align="center">

        <q-card bordered class="my-card shadow-12 shadow-transition">
            <q-card-section  class="bg-primary text-white">
                <div class="text-h5 q-px-md" align="left">
                    <q-icon name="description" />
                    {{ proposta.compagnia }}
                </div>
            </q-card-section>


            <q-card-section class="q-pt-none text-left" style="padding-left: 24px; padding-right: 24px;">
                <strong style="font-size: 16px; color: #A0A0A0;">{{ proposta.label }}</strong>
                <br>
                <div align="left" class="q-pb-md q-pt-md">
                    <span class="text-h3" name="premio_annuale">{{ premio_da_mostrare }}</span><br>
                    <span name="premio_semestrale">{{ premio_semestrale_da_mostrare }}</span><br>
                <!--    <span v-show="!is_tailormade" name="provvigioni">Provvigioni: € {{ provvigioni_da_mostrare }}</span> //-->
                </div>
            </q-card-section>

            <q-separator inset />

            <q-card-section v-show="!is_tailormade">
                <div align="left">

                    <q-expansion-item
                        v-if="premio > 0"
                        expand-separator
                        icon="mdi-text-box-check-outline"
                        label="Documenti specifici tariffa"
                        caption="Clicca QUI per visualizzarli"
                        style="font-size: 18px;"
                        v-model="show_document"
                        label-lines
                    >
                        <q-list>
                            <q-item
                                clickable
                                v-ripple
                                v-for="(documento, index) in elenco_documenti.documenti"
                                v-bind:key="index"
                                v-bind="documento"
                                @click="ScaricaDocumento(documento.path,documento.id)"
                            >
                                <q-item-section avatar>
                                    <q-icon name="mdi-file-pdf-outline" />
                                </q-item-section>
                                <q-item-section>{{ documento.descrizione }}</q-item-section>
                            </q-item>
                        </q-list>
                    </q-expansion-item>
                </div>
            </q-card-section>

            <q-separator inset />
            <br>
            <q-card-section>
                <q-list>
                    <template
                        v-for="(parametro, index) in proposta.parametri"
                        v-bind="parametro"
                    >

                        <template v-if="parametro.type == 'text'">
                            <q-item v-ripple :disable="!parametro.is_enabled" style="padding: 0px;"
                                v-bind:key="40+index">
                                <q-item-section>
                                    <q-input :disable="!parametro.is_enabled"
                                            :filled="!parametro.is_enabled"
                                            outlined
                                            @input="onValoreModificato"
                                            v-model="parametro.value"
                                            :label="parametro.label" >
                                        <template v-slot:prepend>
                                            &euro;
                                        </template>
                                    </q-input>
                                </q-item-section>
                            </q-item>
                        </template>

                        <template v-if="parametro.type == 'select'">
                            <q-item
                                v-ripple
                                :disable="!parametro.is_enabled"
                                style="padding: 0px;"
                                v-bind:key="40+index"
                            >
                                <q-item-section>
                                    <q-select :disable="!parametro.is_enabled"
                                        :filled="!parametro.is_enabled"
                                        outlined
                                        emit-value
                                        map-options
                                        @input="onValoreModificato"
                                        v-model="parametro.value"
                                        :options="parametro.options"
                                        :label="parametro.label"
                                    />
                                </q-item-section>
                            </q-item>
                        </template>

                        <template v-if="parametro.type=='checkbox'">
                            <q-item v-ripple  :disable="!parametro.is_enabled" style="padding: 0px; margin: 0px;" tag="label"
                        v-bind:key="40+index">

                                <q-item-section side top>
                                    <q-checkbox v-model="parametro.is_checked"
                                        :disable="!parametro.is_enabled"
                                        :filled="!parametro.is_enabled"
                                        @input="onValoreModificato"
                                    />
                                </q-item-section>

                                <q-item-section class="text-left">
                                    <q-item-label v-text="parametro.label" />
                                    <q-item-label caption v-text="parametro.description" />
                                </q-item-section>

                            </q-item>
                        </template>

                    </template>
                <br>
                </q-list>
            </q-card-section>

            <q-card-actions align="right">

                <QQCheckBox label="Seleziona"
                    size="sm"
                    ref="MioCheckBox"
                    name="Seleziona"
                    v-model="proposta.is_checked"
                    @btn-clicked="onBtnClicked"
                />

            </q-card-actions>

        </q-card>
    </div>
</template>

<script>
    import QQCheckBox from "@/components/QQCheckBox";
    import { mapState, mapActions } from "vuex";
    import commonLib from "@/libs/commonLib.js";
    //import { mapFields } from "vuex-map-fields";
    import axios from 'axios';

    export default {
        name: "QQProposta",
        data() {
            return {
                tariffa_selezionata: "",
                show_document: false
                //elenco_documenti: []
            }
        },
        components: {
            QQCheckBox
        },
        watch: {
            premio() {
                this.proposta.premio = this.premio;
            },
            provvigioni() {
                this.proposta.provvigioni = this.provvigioni;
            },
            diritti_aby() {
                this.proposta.diritti_aby = this.diritti_aby;
            }
        },
        computed:{
            //...mapFields("formPreventivo",{
            //    IndiceProdotto: "IndiceProdotto",
            //}),
            ...mapState({
                create_at: state => state.formPreventivo.preventivo.create_at,
                formPreventivo: state => state.formPreventivo
            }),
            proposta_visible() {
                return this.premio_da_mostrare !== "Da quotare";
            },
            isDisabled() {
                return parseInt(this.premio) === 0;
            },
            premio() {
                // La truttura dei calcoli è la seguente:
                // 0 = Premio annuale
                // 1 = Provvigioni annuale
                // 2 = Diritti ABY
                // 3 = Importo consulenza minima
                // 4 = Importo consulenza massima
                // 5 = Aliquota consulenza
                // 6 = Premio semestrale
                // Il premio restituito è un array dove 0 è l'importo e 1 è la provvigione
                if (commonLib.isEmpty(this.proposta.calcoli[this.stringa_combinazione])) return 0;

                //console.log("premio calcoli:",this.proposta.calcoli);

                return parseInt(this.proposta.calcoli[this.stringa_combinazione][0]);
            },
            premio_semestrale() {
                if (commonLib.isEmpty(this.proposta.calcoli[this.stringa_combinazione])) return 0;
                return parseInt(this.proposta.calcoli[this.stringa_combinazione][6]);
            },
            premio_da_mostrare() {
                if(this.premio === 0) {
                    return "Da quotare";
                }
                return "€ "+this.premio.toFixed(2);
            },
            premio_semestrale_da_mostrare() {
                if ((commonLib.isEmpty(this.premio_semestrale)) || (this.premio_semestrale === 0)) {
                    return "Frazionamento semestrale non disponibile";
                }

                return "Possibile Frazionamento semestrale "+this.premio_semestrale;
            },
            provvigioni() {
                // Il premio restituito è un array dove 0 è l'importo e 1 è la provvigione
                if (this.premio === 0) return "";
                if (commonLib.isEmpty(this.proposta.calcoli[this.stringa_combinazione])) return 0;

                //console.log("provvigioni calcoli:",this.proposta.calcoli);

                return this.proposta.calcoli[this.stringa_combinazione][1];
            },
            diritti_aby() {
                if (commonLib.isEmpty(this.proposta.calcoli[this.stringa_combinazione])) return 0;

                //console.log("diritti calcoli:",this.proposta.calcoli);

                var valore_diritti = this.proposta.calcoli[this.stringa_combinazione][2];
                if (commonLib.isEmpty(valore_diritti)) valore_diritti = 0;
                return parseFloat(valore_diritti);
            },
        /*    percentuale_consulenza_minima() {
                var percentuale = this.proposta.calcoli[this.stringa_combinazione][3];
                if (commonLib.isEmpty(percentuale)) percentuale = 0;
                return parseInt(percentuale);
            },
            percentuale_consulenza_massima() {
                var percentuale = this.proposta.calcoli[this.stringa_combinazione][4];
                if (commonLib.isEmpty(percentuale)) percentuale = 0;
                return parseInt(percentuale);
            },*/
            importo_consulenza_minimo() {
                if (commonLib.isEmpty(this.proposta.calcoli[this.stringa_combinazione])) return 0;

                //console.log("consulenza minima calcoli:",this.proposta.calcoli);

                var importo = this.proposta.calcoli[this.stringa_combinazione][3];
                if (commonLib.isEmpty(importo)) importo = 0;
                return parseInt(importo);
            },
            importo_consulenza_massimo() {
                if (commonLib.isEmpty(this.proposta.calcoli[this.stringa_combinazione])) return 0;

                //console.log("consulenza massima calcoli:",this.proposta.calcoli);

                var importo = this.proposta.calcoli[this.stringa_combinazione][4];
                if (commonLib.isEmpty(importo)) importo = 0;
                return parseInt(importo);
            },
            percentuale_aliquota_consulenza() {
                var percentuale = this.proposta.aliquota_consulenza_collaboratore;

                return percentuale;
            },
            provvigioni_da_mostrare() {
                return this.provvigioni;
            },
            stringa_combinazione() {
                let stringa = "";

                this.proposta.parametri.forEach(item => {
                    let value = "";
                    switch(item.type) {
                        case "checkbox":
                            value = item.is_checked ? "1" : "0";
                        break;
                        default:
                            value = item.value;
                        break;
                    }
                    stringa += item.index+":"+value+",";
                });

                stringa = stringa.replace(/,$/gm,"");

                return stringa;
            },
        },
        props: {
            proposta: {
                type: Object,
                required: true
            },
            is_tailormade: {
                type: Boolean,
                required: false,
                default: false
            },
            elenco_documenti: {
                type: Object,
                required: true
            }
        },
        methods: {
            onValoreModificato() {
                var dati = {
                    is_checked: this.proposta.is_checked,
                    premio: this.premio,
                    premio_semestrale: this.premio_semestrale,
                    provvigioni: this.provvigioni,
                    diritti_aby: this.diritti_aby,
                    importo_min_consulenza: this.importo_consulenza_minimo,
                    importo_max_consulenza: this.importo_consulenza_massimo,
                    percentuale_aliquota_consulenza: this.percentuale_aliquota_consulenza
                };

                this.$emit('update:is_checked', dati);
            },
            async ScaricaDocumento(url,name) {

                //console.log("name:",name);

                if (url === "Documento non trovato") {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Nessun documento presente nello storage'
                    });
                    return;
                }

                await axios({
                     url:url,
                     method:'GET',
                     responseType: 'blob',
                    headers:{ Authorization:""}
                 })
                 .then((response) => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = "_blank";
                    link.setAttribute('download', name+".pdf");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                 });

            },
            GestisciPropostaSelezionata() {
                this.value = true;
                this.$emit('btn-proposta-clicked', this.proposta.IDTariffa)
            },
            onBtnClicked() {
                var dati = {
                    is_checked: this.proposta.is_checked,
                    premio: this.premio,
                    premio_semestrale: this.premio_semestrale,
                    provvigioni: this.provvigioni,
                    diritti_aby: this.diritti_aby,
                    importo_min_consulenza: this.importo_consulenza_minimo,
                    importo_max_consulenza: this.importo_consulenza_massimo,
                    percentuale_aliquota_consulenza: this.percentuale_aliquota_consulenza
                };

                this.$emit('update:is_checked', dati);
            },
            ...mapActions({
                fetchElencoDocumentiTariffa: "gestioneDocumenti/fetchElencoDocumentiTariffa"
            }),
            async getElencoDocumentiTariffa(idTariffa) {
                var create_at = commonLib.castDateToIso(this.create_at);
                var dati = {
                    id_tariffa: idTariffa,
                    data_create_at: create_at
                }
                var elenco =  await this.fetchElencoDocumentiTariffa(dati);

                return elenco.documenti;
            }
        },
        async mounted() {
            var stringa = this.stringa_combinazione;
            this.proposta.premio = 0;
            this.proposta.provvigioni = 0;
            this.proposta.diritti_aby = 0;
            this.proposta.importo_consulenza_min = 0;
            this.proposta.importo_consulenza_max = 0;

            if (!commonLib.isEmpty(this.proposta.calcoli[stringa])) {
                this.proposta.premio = this.proposta.calcoli[stringa][0];
                this.proposta.provvigioni = this.proposta.calcoli[stringa][1];
                this.proposta.diritti_aby = this.proposta.calcoli[stringa][2];
                this.proposta.importo_consulenza_min = this.proposta.calcoli[stringa][3];
                this.proposta.importo_consulenza_max = this.proposta.calcoli[stringa][4];
            }

            if (commonLib.isEmpty(this.proposta.diritti_aby)) this.proposta.diritti_aby = 0;
        },
        //async updated() {
        async activated() {
            var stringa = this.stringa_combinazione;

            this.proposta.premio = 0;
            this.proposta.provvigioni = 0;
            this.proposta.diritti_aby = 0;
            this.proposta.importo_consulenza_min = 0;
            this.proposta.importo_consulenza_max = 0;

            if (!commonLib.isEmpty(this.proposta.calcoli[stringa])) {
                this.proposta.premio = this.proposta.calcoli[stringa][0];
                this.proposta.provvigioni = this.proposta.calcoli[stringa][1];
                this.proposta.diritti_aby = this.proposta.calcoli[stringa][2];
                this.proposta.importo_consulenza_min = this.proposta.calcoli[stringa][3];
                this.proposta.importo_consulenza_max = this.proposta.calcoli[stringa][4];
            }

            if (commonLib.isEmpty(this.proposta.diritti_aby)) this.proposta.diritti_aby = 0;
        }
    }
</script>

<style scoped lang="scss">
    h4,h5 {
        margin: 4px;
    }
    .my-card {
        width: 100%;
       /* max-width: 500px;*/
        border-radius: 20px;
        padding: 0px;
        border: 1px SOLID #808080;
    }
    .intestazione {
        padding: 6px;
    }
    .q-card__section--vert {
        padding: 0px;
    }

    .q-card--dark {
        background-color: #000;
        color: #fff;
    }

    .LinkMenu {
        color: #800000;
    }

    @media (prefers-color-scheme: dark)  {

        .LinkMenu {
            color: #fff;
        }

    }

</style>
