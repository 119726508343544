<template>
    <!-- ================================================
        Componente specifico per l'elenco delle
        proposte. SI occupa di visualizzare la lista
        dei prodotti selezionati nel wizard elencandoli
        singolarmente e visualizzando il premio totale.
        Utilizza lo store per gestire la visualizzazione
        dei dati.
         ================================================= //-->
    <div class="carrello">

        <q-list bordered separator align="left" class="shadow-12">
            <q-item class="titolo_carrello">
                <q-icon name="mdi-cart-arrow-down" size="48px" />
                <h5 class="q-mb-sm q-mt-sm">Proposte selezionate</h5>
            </q-item>

            <q-item>
                <q-item-section>Tariffa</q-item-section>
                <q-item-section align="center">Proposta</q-item-section>
                <q-item-section align="right">Acquisto garantito</q-item-section>
            </q-item>

            <div v-for="(prodotto, index) in carrello"
                                v-bind:key="index"
                                v-bind="prodotto">

                <div v-for="(proposta, indexProposta) in prodotto.proposte.filter(proposta => {return proposta.is_checked})"
                                v-bind:key="indexProposta"
                                v-bind="proposta">

                    <q-item>
                        <q-item-section>
                            <div align="left">
                                {{proposta.tariffa}}
                            </div>
                        </q-item-section>
                        <q-item-section>
                            <div align="right">{{ getPremioPropostaSelezionata(proposta) }}</div>
                        </q-item-section>
                        <q-item-section>
                            <div align="right" :id="'premio_proposta_'+index">{{ CostoAcquistoGarantito(prodotto,proposta) }}</div>
                        </q-item-section>
                    </q-item>
                </div>

            </div>
            <q-separator inset />
            <q-item class="ModificaSfondo">
                <q-item-section>
                    <div align="left">
                        <!--<q-icon name="done_outline" style="margin-right: 4px;"></q-icon> //-->
                        <strong>Totale preventivo</strong>
                    </div>
                </q-item-section>
                <q-item-section>
                    <div align="right"><strong id="TotaleCarrello">&euro; {{ getTotaleCarrello() }}</strong></div>
                </q-item-section>
                <q-item-section>
                    <div align="right"><strong id="TotaleAcquistoGarantito">&euro; {{ getTotaleAcquistoGarantito() }}</strong></div>
                </q-item-section>
            </q-item>

        </q-list>

    </div>
</template>

<script>
    // import { mapState } from "vuex";
    export default {
        name: "QQCarrello",
        data() {
            return {
                totale_carrello: this.getTotaleCarrello
            }
        },
        props: {
            carrello: {
                type: Array,
                required: true
            }
        },
        computed: {
            //...mapState({
            //    carrello: state => state.formPreventivo.preventivo.prodotti
            //}),
        },
        methods: {
            getPremioPropostaSelezionata(proposta) {
                var premio = Math.round(proposta.premio).toFixed(2);
                if (premio == 0.00) {
                    return "DA QUOTARE";
                }

                return "€ "+premio;
            },
            CostoAcquistoGarantito(prodotto,proposta) {
                var consulenza = parseFloat(prodotto.consulenza.toFixed(2));
                //var premio = Math.round(proposta.premio);
                var premio = parseFloat(proposta.premio.toFixed(2));
                var diritti_aby = parseFloat(proposta.diritti_aby.toFixed(2));
                var totale = consulenza + premio + diritti_aby;

                if (totale === 0.00) {
                    return "DA QUOTARE";
                } else {
                    // Effettua l'arrotondamento
                    var premio_calcolato = Math.ceil(totale);
                    var premio_reale = totale;
                    var incremento = premio_calcolato - premio_reale;
                    consulenza += parseFloat(incremento);

                    totale = consulenza + premio + diritti_aby;
                }

                return "€ "+ totale.toFixed(2);
            },
            getTotaleCarrello() {
                let tot = this.carrello.length;
                let ImportoTotale = 0;

                for (let i=0;i<tot;i++) {
                    let proposte = this.carrello[i].proposte;
                    for (let j=0;j<proposte.length;j++) {
                        if (this.carrello[i].proposte[j].is_checked) {
                            ImportoTotale += parseFloat(this.carrello[i].proposte[j].premio);
                        }
                    }
                }

                return Math.round(ImportoTotale).toFixed(2);
            },
            getTotaleAcquistoGarantito() {
                let premio_totale = 0;
                let diritti_aby = 0;
                let tot = this.carrello.length;
                let consulenza = 0;

                //console.log("totale prodotti:",tot);

                for (let i=0;i<tot;i++) {
                    var prodotto = this.carrello[i];

                    if (prodotto.is_checked) {

                        let proposte = this.carrello[i].proposte;
                        for (let j=0;j<proposte.length;j++) {
                            if (this.carrello[i].proposte[j].is_checked) {
                                //consulenza += parseFloat(prodotto.consulenza);
                                //premio_totale += parseFloat(this.carrello[i].proposte[j].premio);
                                //diritti_aby += parseFloat(this.carrello[i].proposte[j].diritti_aby);

                                premio_totale += parseFloat(prodotto.consulenza.toFixed(2)) +
                                                 parseFloat(this.carrello[i].proposte[j].premio.toFixed(2)) +
                                                 parseFloat(this.carrello[i].proposte[j].diritti_aby.toFixed(2));
                            }
                        }

                        // Aggiusta gli importo per fare l'arrotondamento
                        var premio_calcolato = Math.ceil(premio_totale + parseFloat(consulenza) + parseFloat(diritti_aby));
                        var premio_reale = premio_totale + parseFloat(consulenza) + parseFloat(diritti_aby);
                        var incremento = premio_calcolato - premio_reale;
                        consulenza += parseFloat(incremento);

                    }

                }

                premio_totale += parseFloat(consulenza) + parseFloat(diritti_aby);
                //console.log("totale:",premio_totale,consulenza);

                return premio_totale.toFixed(2);
            }
        }
    }
</script>

<style scoped>
    .q-item--dark {
        background-color: #000;
    }
</style>
