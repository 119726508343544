<template>
    <div>
        <q-stepper v-model="step"
            style="margin-top: 10px;"
            ref="stepper"
            animated
            flat
            alternative-labels
            no-header-navigation
            no-shadow
            transition-next="fade"
            transition-prev="slide-down"
            active-color="deep-orange"
            done-color="blue-grey-4">

        <!--    <q-step :name="1"
                title="Scelta percorso"
                icon="list"
                done-icon="done"
                active-icon="list"
                :done="step > 1">
                <SceltaPercorso
                    @btn-analisi-clicked="onAnalisiClicked"
                    @btn-prodotto-clicked="onProdottoClicked"
                    @on-back-clicked="onBackClicked"
                />
            </q-step> //-->

            <q-step :name="2"
                title="Analisi necessità"
                icon="grading"
                done-icon="done"
                active-icon="grading"
                :done="step > 2">
                <AnalisiNecessita
                    @on-avanti-clicked="onAvantiClicked"
                    @on-back-clicked="onBackClicked"
                />
            </q-step>

            <q-step :name="3"
                title="Selezione prodotti"
                icon="done_all"
                done-icon="done"
                active-icon="done_all"
                :done="step > 3" >
                <SelezioneProdotti
                    @on-avanti-clicked="onAvantiClicked"
                    @on-back-clicked="onBackClicked"
                />
            </q-step>

            <q-step :name="4"
                title="Analisi Rischio"
                icon="list_alt"
                done-icon="done"
                active-icon="list_alt"
                :done="step > 4" >
                <AnalisiRischio
                    @on-avanti-clicked="onAvantiClicked"
                    @on-back-clicked="onBackClicked"
                />
            </q-step>

            <q-step :name="5"
                title="Elenco Proposte"
                icon="list_alt"
                done-icon="done"
                active-icon="list_alt"
                :done="step > 5" >
                <ElencoProposte
                    @on-avanti-clicked="onAvantiClicked"
                    @on-back-clicked="onBackClicked"
                />
            </q-step>

            <q-step :name="6"
                title="Conferma Proposte"
                icon="list_alt"
                done-icon="done"
                active-icon="list_alt"
                :done="step > 6" >
                <ConfermaProposte
                    @on-avanti-clicked="onAvantiClicked"
                    @on-back-clicked="onBackClicked"
                />
            </q-step>

            <q-step :name="7"
                title="Registra Preventivo"
                icon="list_alt"
                done-icon="done"
                active-icon="list_alt"
                :done="step > 7" >
                <DatiRegistraPreventivo
                    @on-avanti-clicked="onAvantiClicked"
                    @on-back-clicked="onBackClicked"
                />
            </q-step>

            <q-step :name="8"
                title="Crea Polizze"
                icon="list_alt"
                done-icon="done"
                active-icon="list_alt"
                :done="step > 8" >
                <DatiCreaPolizze
                    @on-avanti-clicked="onAvantiClicked"
                    @on-back-clicked="onBackClicked"
                />
            </q-step>

        </q-stepper>
        <br><br><br><br>
    </div>
</template>

<script>
// import SceltaPercorso from "@/pages/PiattaformaRami/Preventivi/FormCalcolo/SceltaPercorso"
import AnalisiNecessita from "@/pages/PiattaformaRami/Preventivi/FormCalcolo/AnalisiNecessita"
import SelezioneProdotti from "@/pages/PiattaformaRami/Preventivi/FormCalcolo/SelezioneProdotti"
import AnalisiRischio from "@/pages/PiattaformaRami/Preventivi/FormCalcolo/AnalisiRischio"
import ElencoProposte from "@/pages/PiattaformaRami/Preventivi/FormCalcolo/ElencoProposte"
import ConfermaProposte from "@/pages/PiattaformaRami/Preventivi/FormCalcolo/ConfermaProposte"
import DatiRegistraPreventivo from "@/pages/PiattaformaRami/Preventivi/FormCalcolo/DatiRegistraPreventivo"
import DatiCreaPolizze from "@/pages/PiattaformaRami/Preventivi/FormCalcolo/DatiCreaPolizze"
import store from "@/store"
// import commonLib from "@/libs/commonLib"
import { mapFields} from 'vuex-map-fields'
// import {mapActions} from 'vuex'

export default {
    name: "FormPreventivo",

    components: {
        //SceltaPercorso,
        AnalisiNecessita,
        SelezioneProdotti,
        AnalisiRischio,
        ElencoProposte,
        ConfermaProposte,
        DatiRegistraPreventivo,
        DatiCreaPolizze
    },

    data() {
        return {
            // step: 1
        }
    },
    methods: {
        // ...mapActions({
        //     fetchGetDatiPreventivo: "formPreventivo/fetchGetDatiPreventivo"
        // }),
        onAnalisiClicked() {
            store.commit("formPreventivo/clear");
            this.vuole_analisi_necessita = true;
            this.step = 2;
        },
        onProdottoClicked() {
            store.commit("formPreventivo/clear");
            this.vuole_analisi_necessita = true;
            this.step=2;
        },
        onBackClicked() {
           /* if ((this.vuole_analisi_necessita) || (this.step > 4)) {
                this.step -= 1;
                if (this.step < 2) this.step = 2;
            } else {
                this.step = 2;
            }
            if (this.step == 2) {
                this.vuole_analisi_necessita = false;
            }*/

            this.step -=1;
            if (this.step < 2) this.step = 2;
            if (this.step === 2) {
                this.vuole_analisi_necessita = false;
            }

            //console.log("step:",this.step);

            //this.vuole_analisi_necessita = false;
            //store.commit("formPreventivo/clear");
            //this.$router.push({name : "Preventivi"});

        //    store.commit("formPreventivo/clear");
        //    this.vuole_analisi_necessita = true;
        //    this.step=2;

        },
        onAvantiClicked(DoppioStep) {
            this.step += 1;
            if (DoppioStep) {
                this.step += 1;
            }
        }
    },
    computed: {
        ...mapFields('formPreventivo', [
            "vuole_analisi_necessita",
            "step"
        ]),
        tasto_avanti_is_visible() {
            return (
                this.step > 2 || this.step <= 4
            )
        },
        tasto_avanti_label() {

            if(this.step == 4) {
                return "Calcola";
            }

            return "Avanti";
        }
    }
}

</script>

<style>

</style>
