<template>
    <div class="q-pa-md row justify-center">
        <!-- ==================================================
        Scansiona prima quelli che hanno il flag
        is_consigliato = true
        ======================================================= //-->

        <fieldset style="width: 70%;" class="q-mb-md" v-if="PulsantiConsigliati.length > 0">
            <legend class="text-weight-bolder">Prodotti consigliati</legend>
                <br>
                <div class="row justify-center">

                    <div v-for="(pulsante, index) in PulsantiConsigliati"
                                v-bind:key="index"
                                v-bind="pulsante"
                                class="col-md-4 col-12 text-center">

                    <!--    <QQCheckBox
                            :label="pulsante.label"
                            iconOn="mdi-check-bold"
                            iconOff=""
                            v-model="pulsante.is_checked"
                            @btn-clicked="onBtnClicked(pulsante)"
                            shadow
                            size="md"
                            icon="mdi-account-arrow-right-outline"
                        /> //-->

                        <QQCheckBox
                            :label="pulsante.label"
                            iconOn="mdi-check-bold"
                            iconOff=""
                            v-model="pulsante.is_checked"
                            @btn-clicked="onBtnClicked(pulsante)"
                            shadow
                            size="md"
                            :icon="pulsante.icon"
                        />

                        <br>
                        <strong>{{ messaggioMotivoRinuncia(pulsante.motivo_rinuncia) }}</strong>
                    </div>
                </div>

        </fieldset>

        <fieldset style="width: 70%;" v-if="PulsantiSuggeriti.length > 0">
            <legend class="text-weight-bolder">Altri prodotti dal Catalogo</legend>
            <br>
            <div class="row justify-center">
                <div v-for="(pulsante, index) in PulsantiSuggeriti"
                                    v-bind:key="index"
                                    v-bind="pulsante"
                                    class="col-md-4 col-12 text-center">
                    <QQCheckBox
                        :label="pulsante.label"
                        iconOn="mdi-check-bold"
                        iconOff=""
                        v-model="pulsante.is_checked"
                        shadow
                        size="md"
                    />

                </div>
            </div>

        </fieldset>

        <q-dialog v-model="persistent" persistent transition-show="scale" transition-hide="scale" >
            <q-card  style="width: 500px">
                <q-card-section>
                    <div class="text-h6">Specifica il motivo della disattivazione</div>
                </q-card-section>

                <q-card-section class="q-pt-none">

                    <q-option-group
                        v-model="model"
                        :options="domande_rinuncia"
                        name="motivo_rinuncia"
                    />

                <!--    <q-select

                        outlined
                        v-model="model"
                        :options="domande_rinuncia"
                        label="Seleziona"
                        name="motivo_rinuncia"
                        emit-value
                        map-options
                    /> //-->
                </q-card-section>

                <q-card-actions align="right" class="text-teal">
                    <q-btn color="primary" label="OK" v-close-popup @click.native="ModificaRisposta"/>
                </q-card-actions>
            </q-card>
        </q-dialog>

    </div>
</template>

<script>
    import QQCheckBox from "@/components/QQCheckBox"
    //import commonLib from "@/libs/commonLib"

    export default {
        name: "QQSceltaProdotti",
        data() {
            return {
                values: [],
                persistent: false,
            /*    options: [
                    {label: "Non risponde", value: "NonRisponde"},
                    {label: "Ho gia una copertura di questo tipo", value: "PolizzaGiaFatta"},
                    {label: "Non ho interesse a farla", value: "NonInteressa"},
                    {label: "Costa troppo", value: "TroppoCara"},
                    {label: "Non mi fido", value: "NonSiFida"},
                    {label: "E` gia tanto se ne faccio una", value: "SoloUna"},
                    {label: "Non sono uno sfigato", value: "NoSfiga"},
                ],*/
                model: "",
                pulsante_selezionato: []
            }
        },
        components: {
            QQCheckBox
        },
        props: {
            pulsanti: {
                type: Array,
                required: true
            },
            domande_rinuncia: {
                type: Array,
                required: true
            }
        },
        computed: {
            PulsantiConsigliati() {
                return this.pulsanti.filter( pulsante => {
                    return pulsante.is_consigliato;
                })
            },
            PulsantiSuggeriti() {
                return this.pulsanti.filter( pulsante => {
                    return !pulsante.is_consigliato;
                })
            },
        },
        methods: {
            messaggioMotivoRinuncia(value) {
                // scansiono tutti i motivi e recupero la label invece del valore
                let MotiviFiltrati = this.domande_rinuncia.filter(domanda => {
                    return domanda.value == value;
                });

                if (MotiviFiltrati.length == 1) {
                    return MotiviFiltrati[0].label;
                }

                return "";
            },
            onBtnClicked(pulsante) {
                if (!pulsante.is_checked) {
                    this.pulsante_selezionato = pulsante;
                    this.model = ""
                    this.persistent = true;
                } else {
                    pulsante.motivo_rinuncia = "";
                }
            },
            ModificaRisposta() {
                //if (!commonLib.isUndefined(this.$refs.RispostaMotivoRinuncia.value.value)) {
                if (this.model !== "") {
                    this.pulsante_selezionato.motivo_rinuncia = this.model; // this.$refs.RispostaMotivoRinuncia.value.value;
                } else {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Devi indicare il motivo della deselezione del prodotto'
                    }).onOk(() => {

                    }).onCancel(() => {

                    }).onDismiss(() => {

                    })

                    this.pulsante_selezionato.is_checked = true;
                }
            }
        }

    }
</script>

<style scope>
    /*strong {
        color: #0000FF;
    }
    fieldset {
        margin-bottom: 16px;
    }*/
</style>
